import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';

import {AssetService} from '../services/asset_service';
import {MetadataSchema} from '../services/schema_api_service';

/**
 * A dropdown to select schemas.
 */
@Component({
  selector: 'mam-select-schema',
  templateUrl: './select_schema.ng.html',
  styleUrls: ['./select_schema.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectSchema {
  /**
   * Display placeholder on the dropdown.
   */
  @Input() placeholder = 'Choose a schema';

  /**
   * For analytic logging purpose.
   */
  @Input() resourceKey?: string;

  /**
   * This is the form control for the dropdown.
   */
  @Input() control!: UntypedFormControl;

  /**
   * List of all available schemas.
   */
  @Input() schemas: MetadataSchema[] = [];
  @Input() showLabel: boolean = true;

  /**
   * Applies thin border style that is designed to match metadata fields style.
   *
   * TODO: Currently this style is not used only by
   * right_panel/sync_metadata_dialog.ts. Consider restyling
   * sync_metadata_dialog and make this style default.
   */
  @HostBinding('class.metadata-field-style')
  @Input()
  metadataFieldStyle = false;

  constructor(
      private readonly assetService: AssetService,
  ) {}

  formatSchemaTitle(schema?: MetadataSchema) {
    if (!schema) return 'No schema';
    return schema.title;
  }

  compareSchemas(schema1: MetadataSchema, schema2: MetadataSchema) {
    return schema1?.name === schema2?.name;
  }

  formatSchemaName(schema?: MetadataSchema) {
    return this.assetService.formatSchemaName(schema?.name);
  }
}
