<p class="schema-title" [ngClass]="{'schema-title--invalid' : control.status === 'INVALID'}">SCHEMA <span *ngIf="control.status !== 'DISABLED'">*</span></p>
<div [hidden]="control.status === 'DISABLED'">
  <mat-form-field class="schema-selector" [class.metadata-field]="metadataFieldStyle" subscriptSizing="dynamic" appearance="outline">
    <mat-select [formControl]="control" mam-firebase-ga="Open schema selection panel"
      [mam-firebase-resource]="resourceKey" disableOptionCentering [compareWith]="compareSchemas"
      [placeholder]="placeholder" panelClass="select-schema-panel-mat-select">
      <!-- Selected schema -->
      <mat-select-trigger>
        {{ formatSchemaTitle(control.value) }}
      </mat-select-trigger>

      <!-- List of schemas -->
      <div class="scrollable-options">
        <mat-option class="metadata-item" *ngFor="let schema of schemas" mam-firebase-ga="Select schema panel"
          [mam-firebase-resource]="schema.name" [attr.title]="formatSchemaName(schema)" [value]="schema">
          {{ formatSchemaTitle(schema) }}
        </mat-option>
      </div>
    </mat-select>
  </mat-form-field>
</div>
<div [hidden]="control.status !== 'DISABLED'">
  <div class="schema-wrapper">
    <div class="schema-no-edit-border">
      <span class="schema-no-edit-value">{{ formatSchemaTitle(control.value) }}</span>
    </div>
  </div>
</div>