import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { BehaviorSubject, combineLatest, firstValueFrom, merge, Observable, of, ReplaySubject } from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  finalize,
  map,
  skip,
  startWith,
  switchMap, take,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';

import { assertTruthy, castExists } from 'asserts/asserts';
import { Site } from 'models';

import { ClipBinsInfo, ClipBinsItem } from '../clip_bins/models/clip_bin.model';
import { ClipBinsInfoService } from '../clip_bins/services/clipbins_info.service';
import { isErrorResponse } from '../error_service/error_response';
import { StatusCode } from '../error_service/status_code';
import { FeatureFlagService } from '../feature_flag/feature_flag_service';
import { AnalyticsEventType, FirebaseAnalyticsService } from '../firebase/firebase_analytics_service';
import { ClipLocalStorage } from '../models/storage.model';
import { AssetService, Clip } from '../services/asset_service';
import { Bin, BinsChange, BinService } from '../services/bin.service';
import { BinListFilter } from '../services/bin_api.service';
import { MediaCacheService } from '../services/media_cache_service';
import { SnackBarService } from '../services/snackbar_service';
import { StateService } from '../services/state_service';
import { TableUtils } from '../services/table_utils';
import { UtilsService } from '../services/utils_service';
import { CreateBinDialog } from '../shared/create_bin_dialog';
import { DeleteClipDialog } from '../shared/delete_clip_dialog';
import { ExportAssetDialog, ExportAssetDialogInputData } from '../shared/export_asset_dialog';
import { GetLinkForVideoDialog } from '../shared/get_link_for_video_dialog';
import { RenameClipDialog } from '../shared/rename_clip_dialog';
import { TrimClipDialog } from '../shared/trim_clip_dialog';
import { SharedLinkClipBinService } from '../shared_clipbin/services/shared_link_clipbin.service';

import { ClipbinStorageService } from './../services/storage/clip_bin_storage.service';
import { MoveClipAction } from './move_clip_button';

const BINS_PAGE_SIZE = 24;
const CLIPS_PAGE_SIZE = 24;
const SEARCH_DEBOUNCE = 500;

/**
 * Clips sort state:
 * - sorted - whole current clips set is sorted;
 * - partially sorted - some initial portions were sorted but at least last portion isn't;
 * - unsorted - clips are in order received from back end;
 */
export enum ClipsSortState {
    SORTED,
    PARTIALLY_SORTED,
    UNSORTED
}

/**
 * Clips and clipbins lists, and the main tab of the persistent panel.
 */
@Component({
    selector: 'mam-clip-bin-selection',
    templateUrl: './clip_bin_selection.ng.html',
    styleUrls: ['./clip_bin_selection.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClipBinSelection implements OnInit, OnDestroy {
    /** Triggered when user chooses to move or copy a clip. */
    @Output() readonly clipMoveRequested = new EventEmitter<ClipMoveEvent>();

    @ViewChild('scrollView') scrollView?: ElementRef<HTMLElement>;

    @ViewChild('binsScrollableView') binsScrollableView!: ElementRef<HTMLElement>;

    @ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>;

    @ViewChild(MatSelect) clipbinSelect!: MatSelect;

    @ViewChildren('clipCard', { read: ElementRef }) clipCardList!: QueryList<ElementRef>;

    /** FormControl for select dropdown */
    selectedBin = new UntypedFormControl();

    /** FormControl for search clipbins in the dropdown list */
    readonly binsSearchControl = new UntypedFormControl();

    readonly clipsSearchControl = new UntypedFormControl();

    /**
     * Whether there are clipbins in the system, regardless of the dropdown
     * filter. `undefined` means that we do not know yet.
     */
    hasClipBins?: boolean;

    clipbins$ = new ReplaySubject<Bin[]>(1);

    clips$ = this.stateService.currentPersistentClips$;

    /** Snapshot of clip names. */
    clipsSet: Set<string> = new Set<string>();

    /** List of index track for changed clips and -1 for unchanged. */
    changedTracks: number[] = [];

    /** Whether there are any audio changes in the current clips. */
    hasAudioChanges: boolean = false;

    firstPageLoad: boolean = true;

    /** Information from Firebase about the current clipbin */
    clipBinInfo: ClipBinsInfo | undefined;

    selectedSite?: Site;

    /**
     * Enables/disables trim operation (shows/hides 'scissors' icon).
     * Theoretically it might be enough to use stateService#clipMarking$ and have on the template
     * condition like '*ngIf="... && tplState.clipMarking"' instead of the
     * '*ngIf="... && enabledTrim"'. But 'clipMarking' is such an asynchronous property and this
     * asynchronity leads to the behavior when during clips switching in the clips bin 'scissors' icon
     * become visible for a very short period of time for the newly selected clip.
     * Sunchronous 'enabledTrim' allows to avoid this behavior.
     */
    trimEnabled = false;

    enableClipTrimFF = this.featureFlag.featureOn('enable-clip-trim');
    enableClipsSortFF = this.featureFlag.featureOn('enable-clips-sort');

    disabledExportTooltip = 'The live asset is not in your current site.';

    disableDragAndDrop = false;

    clipDragging = false;

    get dragStartDelay(): number {
        const isTouchPointer = window.matchMedia('(pointer: coarse)').matches;
        return isTouchPointer ? 650 : 0;
    }

    /**
     * Set of clip names manually selected for a bulk operation. When the
     * selection is empty, all clips will be used for the bulk operation.
     */
    multiSelection = new Set<string>();

    readonly clipbinsOwner$ = this.stateService.clipbinsOwner$;

    readonly loadingClips$ = new BehaviorSubject(false);

    readonly loadingBins$ = new BehaviorSubject(false);

    /**
     * Used to ensure that the selected clipbin is an option of the matSelect
     * list and hence is a valid selection.
     */
    extraBinOption?: Bin;

    sortState = ClipsSortState.UNSORTED;

    constructor(
        private readonly clipBinsInfoService: ClipBinsInfoService,
        readonly stateService: StateService,
        readonly window: Window,
        private readonly binService: BinService,
        private readonly assetService: AssetService,
        private readonly sharedLinkClipBinService: SharedLinkClipBinService,
        readonly cdr: ChangeDetectorRef,
        private readonly snackBar: SnackBarService,
        private readonly dialog: MatDialog,
        private readonly mediaCache: MediaCacheService,
        private readonly utils: UtilsService,
        private readonly tableUtils: TableUtils,
        private readonly featureFlag: FeatureFlagService,
        private readonly clipbinStorageService: ClipbinStorageService,
        private readonly analyticsService: FirebaseAnalyticsService,
    ) {
        this.mediaCache.state.selectedSite$.pipe(takeUntil(this.destroyed$)).subscribe((site) => {
            this.cdr.markForCheck();
            this.selectedSite = site;
        });

        // Show a progress bar on top of the persistent panel when either clips
        // or clipbins are being fetched.
        combineLatest([this.loadingClips$, this.loadingBins$])
            .pipe(
                takeUntil(this.destroyed$),
                finalize(() => {
                    this.stateService.isPanelLoading$.next(false);
                })
            )
            .subscribe(([loadingClips, loadingBins]) => {
                this.stateService.isPanelLoading$.next(loadingClips || loadingBins);
            });

        this.clipbinsOwner$.pipe(skip(1), distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe(() => {
            // Prepare selection of the first clipbin.
            this.selectFirstBinIfUnselected = true;
            // Hide all current clipbins from the dropdown selector so that it
            // appears empty until the new owner bins are loaded.
            this.clipbins$.next([]);
            // Clear the current clipbin selected since it may not belong to the
            // new owner type. This also allows the first clipbin to be selected
            // next.
            this.stateService.persistentBinName$.next(undefined);
            // Open the clipbins dropdown which will refresh the list of clipbins
            // and select the first one available.
            this.clipbinSelect.open();
        });

        this.stateService.clipMarking$.pipe(takeUntil(this.destroyed$)).subscribe((clipMarking) => {
            this.trimEnabled = !!clipMarking;
            this.cdr.markForCheck();
        });
    }

    ngOnInit() {
        this.hookClipbinsPaginationReset();

        this.hookClipsPaginationReset();

        this.hookClipbinsUpdates();

        this.hookClipsUpdates();

        this.hookClipsAutoScroll();

        // Synchronize the form control with persistentBinName$ (1/2)
        this.selectedBin.valueChanges.subscribe((clipbin?: Bin) => {
            if (this.currentClipbinName() !== clipbin?.name) {
                if (clipbin?.name) {
                  this.retrieveAndStoreClipbinInfo(clipbin?.name);
                  this.fetchAndStoreClipBinOwner(clipbin?.name);
                }
                this.stateService.persistentBinName$.next(clipbin?.name);
            }
        });

        // Synchronize the form control with persistentBinName$ (2/2)
        this.stateService.persistentBinName$
            .pipe(
                switchMap((clipbinName) => {
                    return clipbinName ? this.binService.getBin(clipbinName) : of(undefined);
                }),
                takeUntil(this.destroyed$)
            )
            .subscribe((response) => {
                // Regular case, select the clipbin.
                if (!isErrorResponse(response)) {
                    if (response?.name === this.currentClipbinName()) {
                        // Add the loaded bin as an option of the dropdown, otherwise
                        // selecting it may result in a placeholder display.
                        this.extraBinOption = response;
                        this.cdr.detectChanges();
                        this.selectedBin.setValue(response, { emitEvent: false });
                        // Handle storage data
                        if (response?.name) {
                          this.retrieveAndStoreClipbinInfo(response?.name);
                          this.fetchAndStoreClipBinOwner(response?.name);
                        }
                    }
                    return;
                }

                // Unselect missing clipbin.
                this.selectedBin.setValue(undefined, { emitEvent: false });

                if (response.status === StatusCode.NOT_FOUND) {
                    this.snackBar.error('Selected clip bin is not found.');
                } else {
                    this.snackBar.error({
                        message: 'Could not load selected clip bin',
                        details: response.message
                    });
                }
            });

        // Refresh selected bin whenever a clipbin or clip is updated since the
        // clipbin resource may have changed (itself, or its assetCount).
        combineLatest([this.binService.binsUpdated$, this.assetService.clipUpdated$])
            .pipe(skip(1), takeUntil(this.destroyed$))
            .subscribe(() => {
                if (this.currentClipbinName()) {
                    this.stateService.persistentBinName$.next(this.currentClipbinName());
                }
            });

        // Refresh selection whenever the list of clips is updated, for instance if
        // a new clipbin is loaded, of if a selected clip was moved or deleted.
        this.clips$.pipe(takeUntil(this.destroyed$)).subscribe((clips) => {
            this.clipsSet = new Set(clips.map((clip) => clip.name));
            this.cdr.detectChanges();
            const multiSelection = new Set(this.multiSelection);
            for (const selection of multiSelection) {
                if (!this.clipsSet.has(selection)) {
                    multiSelection.delete(selection);
                }
            }
            // Re-assign Set to trigger change detection
            this.multiSelection = multiSelection;
        });
        this.trackStoredClips();
    }

    /**
     * Retrieve clipbin information from Firestore and
     * store it in local storage using ClipbinStorageService.
     *
     * @param clipbinName The name of the clipbin.
     */
    private retrieveAndStoreClipbinInfo(clipbinName: string): void {
      this.clipBinsInfoService
          .getIASClipBins(clipbinName, true)
          .pipe(
              take(1),
              map((clipbin) => clipbin[0] || undefined),
              finalize(() => {
                  this.trackStoredClips();
              })
          )
          .subscribe({
              next: (clipbin) => {
                  this.clipBinInfo = clipbin;
                  if (!clipbin) {
                      this.clipbinStorageService.clear();
                      return;
                  }

                  // If title is missing update title using this.selectedBin value if available
                  if (!clipbin.title && this.selectedBin.value.title) {
                      this.clipBinsInfoService.updateTitle(clipbin.id, this.selectedBin.value.title);
                  }
                  const clipsToStorage = {
                      clips: this.convertToClipBinStorage(clipbin.clips),
                      clipBinsId: clipbin.id,
                      ownerId: clipbin.ownerId
                  };
                  this.clipbinStorageService.setClipBinSharing(clipsToStorage);
                  this.firstPageLoad = false;
              },
              error: (error) => this.snackBar.error(error)
          });
    }

    /**
     * Convert a list of clips from interface ClipBinsItem to ClipLocalStorage.
     *
     * @param clips The list of clips to convert with interface ClipBinsItem.
     * @returns The converted list of clips with interface ClipLocalStorage with .
     */
    convertToClipBinStorage(clips: ClipBinsItem[]): ClipLocalStorage[] {
      return clips?.map((clip) => {
        return {
          name: clip.name,
          title: clip.title,
          // If is the first load or the audio track is the same as the current track
          // it should be counted as pristine.
          initialLoad: this.firstPageLoad || (clip.audioTrack.current === clip.audioTrack.current),
          // if first load reset the audio track to current track
          audioTrack: this.firstPageLoad ? {
            previous: clip.audioTrack.current,
            current: clip.audioTrack.current,
            default: clip.audioTrack.current
          } : clip.audioTrack,
        };
      }) ?? [];
    }

    /**
     * Fetch the owner of the clip bin from Firebase and store it in local storage.
     *
     * @param clipbinName The name of the clipbin.
     * @param shouldClear Whether to clear the local storage before storing the owner.
     */
    private fetchAndStoreClipBinOwner(clipbinName: string, shouldClear: boolean = false) {
      const name = encodeURIComponent(clipbinName);
      const clipbinOwner$ = this.clipBinsInfoService.retrieveClipBinOwner(name);
      const clipbinSharedOwner$ = this.sharedLinkClipBinService.retrieveIASClipBinOwner(name);

      // Validates if the collection ias-clipbins-info has the owner information
      // if not, search for the owner inside ias-clipbin-shared-link
      clipbinOwner$.pipe(
        switchMap(ownerValue => ownerValue ? of(ownerValue) : clipbinSharedOwner$)
      ).subscribe({
            next: (ownerId: string) => {
              if (shouldClear) this.clipbinStorageService.clear();
                if (!ownerId) return;
                this.clipbinStorageService.updateOwner(ownerId);
            },
            error: (error) => {
                this.snackBar.error(error);
            }
        });
    }

     trackStoredClips(): void {
        this.clipbinStorageService.store$.pipe(takeUntil(this.destroyed$)).subscribe((storedClips) => {
            if (!storedClips) return;
            // Check if there are any audio changes and store it
            const clipsMap = new Map(storedClips.clips.map(
              (clip) => {
                // Check if there are any audio changes
                if (!clip.initialLoad) this.hasAudioChanges = true;
                return [clip.name, clip.audioTrack?.current !== undefined ? clip.audioTrack?.current : -1];
              }
            ));
            const trackNumbers: number[] = [];

            for (const el of this.clipsSet) {
                const elIdx = clipsMap.get(el);
                const trackNumber = (elIdx !== undefined) ? elIdx : -1;
                trackNumbers.push(trackNumber);
            }

            this.changedTracks = trackNumbers;
            this.cdr.detectChanges();
        });
    }

    ngOnDestroy() {
        this.clips$.next([]);
        this.destroyed$.next();
        this.destroyed$.complete();
        this.clipbinStorageService.clear();
        this.clipbinStorageService.delete();
        this.clipbins$.complete();
    }

    onSelectOpenedChanged(opened: boolean) {
        if (!opened) {
            // Clear the search input box
            if (this.binsSearchControl.value) {
                this.binsSearchControl.setValue('');
            }
        } else {
            this.searchInput?.nativeElement.focus();
            // Force refresh the list of clipbins when we open the selection.
            this.binService.binsUpdated$.next(undefined);
        }
    }

    compareBins(bin1?: Bin, bin2?: Bin) {
        return bin1?.name === bin2?.name && bin1?.title === bin2?.title && bin1?.assetCount === bin2?.assetCount;
    }

    onNearBottomForClips() {
        // Only gets triggered when nothing is loading, it is not the last page of
        // clips, and no clip is being dragged for reorder.
        if (!this.loadingClips$.value && this.listenToClipsNextPageChange && !this.clipDragging) {
            this.clipsNextPageChange$.next();
        }
    }

    onNearBottomForBins() {
        // Only gets triggered when nothing is loading and it is not the last page
        // of clipbins.
        if (!this.loadingBins$.value && this.listenToBinsNextPageChange) {
            this.binsNextPageChange$.next();
        }
    }

    trackName(index: number, value: Bin | Clip) {
        return value.name;
    }

    openDeleteClip(clipName: string) {
        this.dialog.open(DeleteClipDialog, { data: { name: clipName, bin: this.selectedBin.value } });
    }

    openTrimClip(clip: Clip) {
        const clips = this.cachedClips;
        const clipIndex = clips.indexOf(clip);
        const prevClip = clipIndex > 0 ? clips[clipIndex - 1] : undefined;
        const nextClip = clipIndex < clips.length - 1 ? clips[clipIndex + 1] : undefined;

        const dialogData = { data: { clip, prevClip, nextClip, comp: this } };
        this.dialog.open(TrimClipDialog, dialogData);
    }

    requestClipMove(clip: Clip, action: MoveClipAction) {
       if (action === 'move') this.clipbinStorageService.removeClip(clip.name).subscribe();
        this.clipMoveRequested.emit({ clip, action });
    }

    isVideoShareable(clip: Clip) {
        return this.assetService.isVideoShareable(clip);
    }

    async shareVideo(clip: Clip) {
        assertTruthy(clip.original, 'Expected a clip');
        if (!this.isVideoShareable(clip)) return;

        const trackIndex = this.stateService.currentAssetTrack$.getValue()?.trackIndex;

        this.dialog.open(
            GetLinkForVideoDialog,
            GetLinkForVideoDialog.getDialogOptions({
                asset: clip,
                additionalProperties: { trackIndex: String(trackIndex) ?? '' }
            })
        );
    }

    openCreateBin() {
        this.dialog.open(CreateBinDialog, CreateBinDialog.dialogOptions);
    }

    openRenameClip(name: string, title: string) {
        this.dialog.open(RenameClipDialog, {
            ...RenameClipDialog.dialogOptions,
            data: { name, title, bin: this.selectedBin.value }
        });
    }

    openExportClipDialog(clip: Clip) {
        const config: ExportAssetDialogInputData = { assets: [clip] };
        this.dialog.open(ExportAssetDialog, ExportAssetDialog.getDialogOptions(config));
    }

    /**
     * All VoD clips can be exported. But disable the export option when the live
     * clip is not from the same site as the current site or no selected folder.
     */
    isExportDisabled(clip: Clip): boolean {
        if (!clip.isLive) return false;

        if (this.selectedSite?.siteId && clip.assetMetadata.jsonMetadata['Site']) {
            return this.selectedSite.siteId.toLowerCase() !== clip.assetMetadata.jsonMetadata['Site'].toLowerCase();
        }
        this.disabledExportTooltip = 'Site metadata is missing.';
        return true;
    }

    async dragAndDropClip(event: CdkDragDrop<Clip[]>) {
        if (event.currentIndex === event.previousIndex) return;

        const draggedClip = this.cachedClips[event.previousIndex];
        assertTruthy(draggedClip, 'Expected a clip');

        this.disableDragAndDrop = true;

        // Moving the clip first sets previousClip, nextClip to the right clips.
        moveItemInArray(this.cachedClips, event.previousIndex, event.currentIndex);

        const previousClip = event.currentIndex === 0 ? undefined : this.cachedClips[event.currentIndex - 1].name;

        let nextClip =
            event.currentIndex === this.cachedClips.length - 1
                ? undefined
                : this.cachedClips[event.currentIndex + 1].name;

        let endOfPage = false;

        // If we are dropping the clip to the last position but we have a next page
        // token (hence this is not the last clip of the clipbin), we fetch the next
        // clip with a list call limited to 1 result.
        if (!nextClip && this.clipsNextPageToken) {
            endOfPage = true;
            nextClip = await firstValueFrom(
                this.assetService
                    .searchClips(
                        castExists(this.currentClipbinName()),
                        this.clipsSearchControl.value,
                        this.clipsNextPageToken,
                        1
                    )
                    .pipe(map((response) => (isErrorResponse(response) ? undefined : response.assets[0]?.name)))
            );
        }

        const clipMoved = await firstValueFrom(this.assetService.reorderClip(draggedClip, previousClip, nextClip));

        this.sharedLinkClipBinService.updateClipBinSharedLinkIfExist(this.selectedBin.value.name);

        this.cdr.markForCheck();
        this.clipDragging = false;
        this.disableDragAndDrop = false;

        if (endOfPage) {
            // We want to call a scroll event to fetch the next page of clips if
            // the user reached the end while dragging
            this.scrollView?.nativeElement.dispatchEvent(new Event('scroll'));
        }

        if (!clipMoved) {
            this.snackBar.error('Failed to reorder the clips');
            // Move clip back if the api call fails
            moveItemInArray(this.cachedClips, event.currentIndex, event.previousIndex);
            return;
        }

        // Re-emit the list of clips so that details navigation updates its internal
        // index of the current clip within its clipbin.
        this.clips$.next(this.cachedClips);
        this.trackStoredClips();
    }

    handleSortedClips() {
        this.cachedClipsSorted = this.cachedClips.slice().sort((a, b) => (a.title < b.title ? -1 : 1));
        this.clips$.next(this.cachedClipsSorted);
        this.sortState = ClipsSortState.SORTED;
        this.cdr.markForCheck();
    }

    handleUnsortedClips() {
        this.clips$.next(this.cachedClips);
        this.sortState = ClipsSortState.UNSORTED;
        this.cdr.markForCheck();
    }

    refreshClips() {
        this.manualClipsRefresh$.next(undefined);
    }

    /**
     * Toggles manual selection of a clip for bulk operation. If the "shift" key
     * is pressed, all clips between the last selected one and the current clicked
     * one will be toggled.
     */
    toggleSelection(allClips: Clip[], clip: Clip, multiSelection: Set<string>, shiftPressed = false) {
        const selectedNames = new Set(multiSelection);

        const { itemsToSelect, itemsToUnSelect, anchorName } = this.tableUtils.processMultiSelect({
            items: allClips,
            target: clip,
            selectedNames,
            shiftPressed,
            anchorName: this.selectionAnchorAssetName
        });

        this.selectionAnchorAssetName = anchorName;

        for (const clip of itemsToUnSelect) {
            selectedNames.delete(clip.name);
        }
        for (const clip of itemsToSelect) {
            selectedNames.add(clip.name);
        }

        // Re-assign Set to trigger change detection
        this.multiSelection = selectedNames;
    }

    public clickClipCard(clip: Clip) {
        const mainPage = document.querySelector('main');

        if (mainPage) {
            mainPage.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }

        if (clip.name !== this.stateService.currentAsset$.getValue()?.name) {
            this.trimEnabled = false;
        }
    }

    private readonly searchChanged$: Observable<string> = this.getSearchChanged();

    private readonly destroyed$ = new ReplaySubject<void>(1);

    private readonly manualClipsRefresh$ = new BehaviorSubject(undefined);

    private readonly selectedBinName$: Observable<string | undefined> =
        this.stateService.persistentBinName$.pipe(distinctUntilChanged());

    /**
     * Indicates whether to select the first clipbin available at the end of the
     * next clipbins refresh. If a clipbin is already selected by then, nothing
     * will happen.
     */
    private selectFirstBinIfUnselected = true;

    /**
     * Emits empty string whenever a clipbin is selected, or any search query
     * after a debounce.
     */
    private readonly clipsQuery$: Observable<string> = this.selectedBinName$.pipe(
        switchMap(() => {
            return this.clipsSearchControl.valueChanges.pipe(debounceTime(SEARCH_DEBOUNCE), startWith(''));
        }),
        tap(() => {
            this.resetClipsPagination();
            this.scrollToTopOfClipsScrollView();
        })
    );

    /** Infinite scroll change of clip bin dropdown */
    private cachedBins: Bin[] = [];
    private readonly binsNextPageChange$ = new BehaviorSubject<void>(undefined);
    private binsNextPageToken?: string;
    private listenToBinsNextPageChange = true;

    /** Current read clips in an unsorted state. */
    private cachedClips: Clip[] = [];
    /** Current read clips in a sorted state. */
    private cachedClipsSorted?: Clip[];
    private readonly clipsNextPageChange$ = new BehaviorSubject<void>(undefined);
    private clipsNextPageToken?: string;
    private listenToClipsNextPageChange = true;

    /** Used for range-selection of clips by holding "shift". */
    private selectionAnchorAssetName = '';

    private hookClipbinsPaginationReset() {
        const sources = combineLatest([
            this.searchChanged$,
            this.clipbinsOwner$,
            this.binService.binsUpdated$,
            this.assetService.clipUpdated$
        ]);

        sources.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.resetBinsPagination();
            this.scrollToTopOfBinsScrollableView();
        });
    }

    private hookClipsPaginationReset() {
        // Trigger query clear which resets pagination.
        this.selectedBinName$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            // clipsQuery$ will emit due to the clipbin change, so no need
            // to notify the form control that the query has changed too.
            this.clipsSearchControl.setValue('', { emitEvent: false });
            // Clear old list of clips when a different clipbin is selected.
            this.clips$.next([]);
        });

        combineLatest([this.assetService.clipUpdated$, this.manualClipsRefresh$])
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.resetClipsPagination();
                this.scrollToTopOfClipsScrollView();
            });
    }

    /**
     * The list of clip bins is updated when we type on the search bar, or when
     * a new clipbin is created or when scroll down to the bottom triggers
     * pagination.
     */
    private hookClipbinsUpdates() {
        // Source events that trigger a refresh of clip bins without changing the
        // selected bin.
        const updateBinsKeepSelection$: Observable<UpdateBinRequest> = combineLatest([
            this.searchChanged$,
            this.binsNextPageChange$,
            this.assetService.clipUpdated$
        ]).pipe(map(([query]) => ({ query })));

        // Source events that trigger a refresh of clip bins and reset the
        // selected bin. Contains binsUpdated$ (which exclusively means that a new
        // clip bin has been created).
        const updateBinsResetSelection$: Observable<UpdateBinRequest> = this.binService.binsUpdated$.pipe(
            withLatestFrom(this.searchChanged$),
            map(([binsUpdate, query]) => ({ query, binsUpdate }))
        );

        // Update list of clipbins, either preserving or resetting the selected
        // one.
        merge(
            updateBinsKeepSelection$,
            // updateBinsKeepSelection$ and updateBinsWithSelectionReset$ are both
            // based on BehaviorSubjects and will emit initial values which will
            // result in double emission. skip(1) blocks the first emission from
            // one of the branches preventing double emission.
            updateBinsResetSelection$.pipe(skip(1))
        )
            .pipe(
                tap(() => {
                    this.loadingBins$.next(true);
                }),
                withLatestFrom(this.clipbinsOwner$),
                // Search clipbins from the API.
                switchMap(([{ query, binsUpdate }, owner]) => {
                    const filters: BinListFilter = { title: query, owner };

                    query && this.analyticsService.logEvent('Call API Search Clipbins', {
                      term: query,
                      eventType: AnalyticsEventType.API_CALL,
                    });

                    return this.binService
                        .list(filters, this.binsNextPageToken, BINS_PAGE_SIZE)
                        .pipe(map((response) => ({ response, query, binsUpdate })));
                }),
                takeUntil(this.destroyed$),
                finalize(() => {
                    this.loadingBins$.next(false);
                })
            )
            .subscribe(({ response, query, binsUpdate }) => {
                this.loadingBins$.next(false);

                if (!response) {
                    this.snackBar.error('Clip bins could not be loaded');
                    return;
                }

                this.cdr.markForCheck();

                const { bins, nextPageToken } = response;

                if (!nextPageToken) {
                    this.listenToBinsNextPageChange = false;
                }

                this.cachedBins.push(...bins);
                this.clipbins$.next(this.cachedBins);
                this.binsNextPageToken = nextPageToken;
                this.trackStoredClips();

                // Only show no clipbins placeholder when there is no clipbin
                // and no search.
                this.hasClipBins = Boolean(query || bins.length);

                // If a clipbin was just created, select it.
                if (binsUpdate?.type === 'CREATE') {
                    this.selectedBin.setValue(binsUpdate.item);
                    return;
                }

                // Select the first clipbin by default when this panel is created and
                // we have no other clipbin to select.
                const shouldSelectFirstBin = this.shouldSelectFirstBin();
                this.selectFirstBinIfUnselected = false;

                if (shouldSelectFirstBin && response.bins.length) {
                    const firstBin = response.bins[0];
                    if (firstBin.name !== this.currentClipbinName()) {
                        this.selectedBin.setValue(firstBin);
                    }
                }
            });
    }

    /**
     * When this panel is created, if the current asset is not a clip, load the
     * first clipbin as a default. If the asset is a clip, the Details view
     * will load its clipbin directly.
     */
    private shouldSelectFirstBin() {
        return this.selectFirstBinIfUnselected && !this.currentClipbinName();
    }

    private currentClipbinName() {
        return this.stateService.persistentBinName$.value;
    }

    /**
     * Update list of clips when selected clipbin changes; when the clips search
     * input changes (after a debounce); when a clip is added, deleted or
     * renamed; or when we scrolled down to the bottom.
     */
    private hookClipsUpdates() {
        const source = combineLatest([
            this.clipsQuery$,
            this.assetService.clipUpdated$,
            this.clipsNextPageChange$,
            this.manualClipsRefresh$
        ]);

        source
            .pipe(
                tap(() => {
                    this.loadingClips$.next(true);
                }),
                switchMap(([clipsQuery]) => {
                    const currentClipbinName = this.currentClipbinName();
                    if (!currentClipbinName) {
                        return of({ assets: [], nextPageToken: '' });
                    }
                    return this.assetService.searchClips(
                        currentClipbinName,
                        clipsQuery,
                        this.clipsNextPageToken,
                        CLIPS_PAGE_SIZE
                    );
                }),
                takeUntil(this.destroyed$),
                finalize(() => {
                    this.loadingClips$.next(false);
                })
            )
            .subscribe((response) => {
                this.loadingClips$.next(false);

                if (isErrorResponse(response)) {
                    this.snackBar.error({
                        message: 'Clips could not be loaded',
                        details: response.message,
                        doNotLog: true
                    });
                    return;
                }

                const { assets, nextPageToken } = response;

                if (!nextPageToken) {
                    this.listenToClipsNextPageChange = false;
                }

                this.cachedClips.push(...assets);
                this.clips$.next(this.getSortedClips(assets));

                this.trackStoredClips();
                this.clipsNextPageToken = nextPageToken;

                this.cdr.markForCheck();
            });
    }

    /**
     * Returns sorted clips list:
     * - if it's first clips page/portion:
     *   - return portion as is in case "partial/unsorted" sort state;
     *   - return sorted portion in case "sorted" sort state;
     * - if it's not a first page/portion:
     *   - if something already was sorted, then concat this old "something" with the current portion;
     *   - if nothing was sorted before, then return cachedClips;
     *
     * @see cachedClips
     */
    private getSortedClips(portion: Clip[]): Clip[] {
        if (this.cachedClips.length === portion.length) {
            if (this.sortState) {
                this.sortState = ClipsSortState.UNSORTED;
                return portion;
            }
            return portion.slice().sort((a, b) => (a.title < b.title ? -1 : 1));
        }

        if (this.cachedClipsSorted) {
            this.cachedClipsSorted.push(...portion);
            this.sortState = ClipsSortState.PARTIALLY_SORTED;
            return this.cachedClipsSorted;
        }

        return this.cachedClips;
    }

    private getSearchChanged() {
        // Putting startWith after debounceTime makes the observable emit initial
        // value without the debounce delay.
        // More info: https://stackoverflow.com/a/53991661
        return this.binsSearchControl.valueChanges.pipe(
            debounceTime(SEARCH_DEBOUNCE),
            startWith(''),
            distinctUntilChanged()
        );
    }

    private resetBinsPagination() {
        this.binsNextPageToken = undefined;
        this.cachedBins = [];
        this.listenToBinsNextPageChange = true;
    }

    private scrollToTopOfBinsScrollableView() {
        if (this.binsScrollableView) {
            this.binsScrollableView.nativeElement.scrollTop = 0;
        }
    }

    private resetClipsPagination() {
        this.clipsNextPageToken = undefined;
        this.cachedClips = [];
        this.listenToClipsNextPageChange = true;
    }

    private scrollToTopOfClipsScrollView() {
        if (this.scrollView) {
            this.scrollView.nativeElement.scrollTop = 0;
        }
    }

    private hookClipsAutoScroll() {
        // Scroll clips view to the middle of their scrollable viewport when
        // a clip is selected outside of it.
        this.stateService.currentAsset$
            .pipe(
                // Wait for the view to render the elements so that we can access
                // their DOM offsets.
                delay(0),
                takeUntil(this.destroyed$)
            )
            .subscribe(() => {
                this.utils.scrollToActive(this.scrollView?.nativeElement, '.active');
            });
    }
}

interface UpdateBinRequest {
    query: string;
    binsUpdate?: BinsChange;
}

/**
 * Pass the data we need to move a clip from a child component to parent
 * component
 */
export interface ClipMoveEvent {
    clip: Clip;
    action: MoveClipAction;
}
