import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PublishNeverExpiredService {
  readonly endpoint = '/pubsub/publish-never-expired-message';

  constructor(private http: HttpClient) {
  }

  publishNeverExpiredMessage(email: string, token: string) {
    const data = { email, token };

    return this.http.post<string>(this.endpoint, data).pipe(
      catchError(error => {
        console.error('Error occurred during publishing pub sub message:', error);
        // Return an observable that emits an error
        return throwError('Error occurred during publishing pub sub message');
      })
    );
  }
}
