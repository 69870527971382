<section class="url-area">
    <mat-form-field class="dialog__input url-area__input--spacing" appearance="outline" subscriptSizing="dynamic">
      <mat-icon matPrefix>share</mat-icon>
      <input
          matInput
          readonly
          [value]="hash ? url+hash : url"
          [placeholder]="placeholder"
          [attr.aria-label]="inputLabel">
    </mat-form-field>
    <button
        [disabled]="!url"
        class="copy-url"
        mat-flat-button
        [mam-firebase-ga]="buttonAnalisysLabel"
        [mam-firebase-resource]="title"
        [cdkCopyToClipboard]="hash ? url+hash : url"
        (click)="snackbarClipboardCopy()">
      Copy
    </button>
  </section>
