import {CdkDrag, CdkDropList} from '@angular/cdk/drag-drop';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCard} from '@angular/material/card';
import {MatCheckbox} from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatToolbarModule} from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {RouterModule} from '@angular/router';

import {DetailsModule} from '../details/details_module';
import {FeatureFlagModule} from '../feature_flag/feature_flag_module';
import {FirebaseModule} from '../firebase/firebase_module';
import {PipesModule} from '../pipes/pipes_module';
import {PlayerModule} from '../player/player_module';
import {RightPanelModule} from '../right_panel/right_panel_module';
import {SharedModule} from '../shared/shared_module';

import {SharedLinkClipBin} from './shared_link_clipbin';

@NgModule({
    imports: [
        CommonModule,
        DetailsModule,
        FirebaseModule,
        MatButtonModule,
        MatIconModule,
        MatToolbarModule,
        MatTooltipModule,
        MatDividerModule,
        MatMenuModule,
        PipesModule,
        RouterModule,
        RightPanelModule,
        CdkDrag,
        CdkDropList,
        FeatureFlagModule,
        MatCard,
        MatCheckbox,
        PlayerModule,
        SharedModule,
        NgOptimizedImage,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [SharedLinkClipBin]
})
export class SharedClipBinModule {}
