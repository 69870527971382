import { Inject, Injectable, NgZone } from '@angular/core';
import { deleteDoc, doc, DocumentData, Firestore, getDoc, or, QueryFieldFilterConstraint, QueryFilterConstraint, where } from '@firebase/firestore';
import { EMPTY, firstValueFrom, forkJoin, Observable, of } from 'rxjs';
import { concatMap, defaultIfEmpty, expand, filter, map, reduce, take } from 'rxjs/operators';

import { AuthService } from '../../auth/auth_service';
import { isErrorResponse } from '../../error_service/error_response';
import { FirebaseResolver } from '../../firebase/firebase_resolver';
import { PublishClipBinLinkSyncChildrenTtlService } from '../../internal_pubsub/publish_clipbin_link_sync_children_ttl_service';
import { SharedLink } from '../../models';
import { LIST_ALL_CLIPS_PAGE_SIZE, MAX_CLIPS_COUNT_FOR_BULK } from '../../right_panel/bulk_clips_actions';
import { AssetService, Clip } from '../../services/asset_service';
import { BinWithClips } from '../../services/bin.service';
import { LOCATION_ORIGIN, SharedLinksService } from '../../services/shared_links_service';
import { ClipbinStorageService } from '../../services/storage/clip_bin_storage.service';
import { TimezoneService } from '../../services/timezone_service';
import { SharedLink as ClipSharedLink, IASClipBinShareLinkData } from '../models/shared_link_clipbin.model';

export enum SharedLinkType {
  ALL = 'all',
  CLIPBINS = 'clipbins',
  CLIPS = 'clips'
}

export type ClipBinExpirationDaysOption = 30|7|1;

@Injectable({ providedIn: 'root' })
export class SharedLinkClipBinService {
    constructor(
        private readonly firebaseResolver: FirebaseResolver,
        private readonly assetService: AssetService,
        private readonly sharedLinkService: SharedLinksService,
        private readonly clipbinStorageService: ClipbinStorageService,
        private readonly publishService: PublishClipBinLinkSyncChildrenTtlService,
        private readonly authService: AuthService,
        private readonly timezone: TimezoneService,
        private readonly ngZone: NgZone,
        @Inject(LOCATION_ORIGIN) private readonly origin: string,
    ) {}

  /**
   * Possible number of days before a shared link expires.
   * Value '0' is considered as 'never expired'.
   * For now '0' isn't used and skipped to be shown in the options dropdown.
   * But this value '0' is mandatory here because of the changes in the underlying
   * service(s) related to the 'clip sharing - expiration date never' functionality
   */
  readonly clipbinExpirationDaysOptions:ClipBinExpirationDaysOption[] = [30, 7, 1];

  readonly clipbinDefaultExpirationDays: ClipBinExpirationDaysOption = 30;


  createClipBinShareLink(data: DocumentData) {
    data['emailID'] = this.authService.getUserEmail();
    data['username'] = this.authService.getUserName();

    return this.ngZone.runOutsideAngular(() => {
      return this.firebaseResolver.createFirestoreDoc('ias-clipbin-share-link', data);
    });
  }

  retrieveIASClipBinShareLink(clipbinName: string, isActive: boolean) {
    const constraints: QueryFieldFilterConstraint[] = [
      where('clipBinName', '==', clipbinName),
      where('domain', '==', this.origin),
      where('isActive', '==', isActive),
    ];

    return this.queryIASClipBinShareLink(constraints);
  }

  /**
   * Retrieve the owner of a shared clip bin.
   *
   * @param clipbinName The name of the clip bin.
   * @returns An observable that emits the owner's email ID.
   */
  retrieveIASClipBinOwner(clipbinName: string) {
    const constraints: QueryFieldFilterConstraint[] = [
      where('clipBinName', '==', clipbinName),
      where('isActive', '==', true),
    ];
    return this.queryIASClipBinShareLink(constraints).pipe(map((data) => (data[0] ? data[0].emailID || '' : '')));
  }

  updateClipBinShareLink(clipbinName: string, data: DocumentData) {
    const constraints: QueryFieldFilterConstraint[] = [
      where('clipBinName', '==', clipbinName),
      where('domain', '==', this.origin),
    ];
    return this.updatePartialValueInDocuments('ias-clipbin-share-link', constraints,
        {...data, updateTime: new Date()});
  }

    mapClipBinShareLinkToUIShareLink(clipBinShareLink: IASClipBinShareLinkData): SharedLink {
      const link = new SharedLink({
        title: clipBinShareLink.clipBinTitle,
        ttl: clipBinShareLink.ttl.toPrecision(1),
        name: clipBinShareLink.clipBinName,
        additionalProperties: {},
      });

      link.type = 'CLIPBIN' ;
      link.url = clipBinShareLink.clipBinSharedLink;
      link.originalTtl = clipBinShareLink.ttl;
      link.editableTtl = clipBinShareLink.ttl;
      link.documentId = clipBinShareLink.documentId ?? '';
      link.clipSharedLinks = clipBinShareLink.clipSharedLinks?.map(c => { return {assetName: c.assetName}; });

      return link;
    }

    retrieveActiveIASClipBinShareLinksByUser(userEmail: string, domain: string) {
      const constraints: QueryFilterConstraint[] = [
        or(
          where('emailID', '==', userEmail),
          where('owners', 'array-contains', userEmail)
        ),
        where('domain', '==', domain),
        where('isActive', '==', true)
      ];
      return this.queryIASClipBinShareLink(constraints);
    }

    retrieveAllIASClipShareLinksByUser(userEmail: string, domain: string) {
      const constraints: QueryFieldFilterConstraint[] = [
        where('emailID', '==', userEmail),
        where('domain', '==', domain),
      ];
      return this.queryIASClipBinShareLink(constraints);
    }

    isVideoShareable(clip: Clip) {
      return this.assetService.isVideoShareable(clip);
    }

  createClipBinSharedLinkIfNotExist(
    bin: BinWithClips,
    properties: Record<string, string> | undefined,
    ttl: ClipBinExpirationDaysOption,
    createCallback: () => void,
  ) {
    const encodedClipBinName = encodeURIComponent(bin.name);
    this.retrieveIASClipBinShareLink(encodedClipBinName, true)
      .pipe(take(1))
      .subscribe((response) => {
        if (response.length) {
          createCallback();
          this.recreateClipBinSharedLink(response[0]);
        } else {
          this.createClipBinSharedLink(bin, properties, ttl, createCallback);
        }
      });
  }

  private recreateClipBinSharedLink(link: IASClipBinShareLinkData) {
    let updatedOwners;
    const user = this.authService.getUserEmail();
    const originalOwners = link.owners || [] ;
    if (!originalOwners.includes(user)) {
      updatedOwners = [...originalOwners, user];
    }
    this.updateClipBinSharedLinkTtlOwners(link.clipBinName, this.clipbinDefaultExpirationDays, updatedOwners);
  }

  private createClipBinSharedLink(
      bin: BinWithClips,
      properties: Record<string, string> | undefined,
      ttl: ClipBinExpirationDaysOption,
      createCallback: () => void,
  ) {
    const encodedClipBinName = encodeURIComponent(bin.name);
    const createSharedLinkData = {
      createTime: this.timezone.formatTimestampToISOString(Date.now()),
      clipBinTitle: bin.title,
      clipBinName: encodedClipBinName,
      clipBinSharedLink: this.sharedLinkService.getClipBinLinkUrl(encodedClipBinName),
      domain: this.origin,
      ttl,
      expireTime: this.calculateExpireTime(ttl),
      isActive: true,
      owners: [this.authService.getUserEmail()],
    };
    this.processClipBinSharedLink(createSharedLinkData, true, properties, createCallback);
  }

  updateClipBinSharedLinkIfExist(clipBinName: string) {
    const encodeClipBinName = encodeURIComponent(clipBinName);
    this.retrieveIASClipBinShareLink(encodeClipBinName, true)
      .pipe(
        take(1),
        filter(response => !!response.length),
      )
      .subscribe(response => this.updateClipBinSharedLink(response[0]));
  }

  private updateClipBinSharedLink(clipbinSharedLink: IASClipBinShareLinkData) {
    this.processClipBinSharedLink(clipbinSharedLink);
  }

  private calculateExpireTime(ttl: number): Date {
    return new Date(Date.now() + ttl * 24 * 3600 * 1000);
  }

  private getClipProperties(clip: Clip, properties: Record<string, string>) {
    const trackIndexValue = this.clipbinStorageService.getIndexByName(clip.name);
    const trackIndex = trackIndexValue !== undefined ? String(trackIndexValue) : undefined;
    return trackIndex !== undefined ? {...properties, trackIndex } : properties;
  }

  private setActualTitle(link: ClipSharedLink, clips: Clip[]) {
    const relatedClip = clips.find(clip => clip.name === link.clipName);
    if (relatedClip) {
      link.title = relatedClip.title;
    }
  }

  private preserveClipsOrder(clipSharedLinks: ClipSharedLink[], clips: Clip[]) {
    const clipsNames = clips.map(c => c.name);
    clipSharedLinks.sort((a, b) => clipsNames.indexOf(a.clipName || '') - clipsNames.indexOf(b.clipName || '') );
  }

  private processClipBinSharedLink(
      clipbinSharedLink: IASClipBinShareLinkData,
      create = false,
      properties = {},
      storeCallback: () => void = () => void(0),
  ) {
    const encodedClipBinName = clipbinSharedLink.clipBinName;
    const clipBinName = decodeURIComponent(encodedClipBinName);
    let clipSharedLinks = clipbinSharedLink.clipSharedLinks || [];
    this.getAllClips(clipBinName)
      .pipe(
        filter(clip => this.isVideoShareable(clip)),
        reduce((clips, clip) => {
          clips.push(clip);
          return clips;
        }, [] as Clip[])
      )
      .subscribe(clips => {
        // handle rename clips
        clipSharedLinks.forEach(link => this.setActualTitle(link, clips));

        // handle removed clips
        clipSharedLinks = clipSharedLinks.filter(link => link.clipName && clips.map(c => c.name).includes(link.clipName));

        // handle added clips
        const addedClips = clips.filter(clip => !clipSharedLinks.map(csl => csl.clipName).includes(clip.name));
        const sharedLinksForAddedClips$ = addedClips.map(clip =>
          {
          return this.createClipShareWithProperties(clip, properties);
        });
        forkJoin(sharedLinksForAddedClips$).pipe(defaultIfEmpty([]))
          .subscribe(results => {
            clipSharedLinks.push(...results);
            if (!create) {
              this.preserveClipsOrder(clipSharedLinks, clips);
            }

            const sharedLinkStoreData = { clipSharedLinks };
            if (create) {
              Object.assign(sharedLinkStoreData, clipbinSharedLink);
            }

            const storeOperation = create ?
                this.createClipBinShareLink(sharedLinkStoreData) :
                this.updateClipBinShareLink(encodedClipBinName, sharedLinkStoreData);
            storeOperation.then(() => storeCallback());
          });
      });
  }

    createClipShareWithProperties(clip: Clip, properties: {}) {
      const clipProperties = this.getClipProperties(clip, properties);
      return this.sharedLinkService.createLink(clip, clipProperties)
        .pipe(
          filter(response => !isErrorResponse(response)),
          map(link => {
            const sharedLink = link as SharedLink;
            return {
              assetName: sharedLink.name,
              title: sharedLink.title,
              link: this.sharedLinkService.getClipBinAssetLinkHash(sharedLink),
              clipName: clip.name,
              duration: clip.duration,
            };
          })
        );
    }

    getAllClips(clipbinName: string): Observable<Clip> {
      let count = 0;
      return this.assetService
        .searchClips(clipbinName, undefined, '', LIST_ALL_CLIPS_PAGE_SIZE)
        .pipe(
          // Expand all pages of clips.
          expand((response) => {
            if (isErrorResponse(response)) return EMPTY;
            if (!response?.nextPageToken) return EMPTY;
            // Stop loading more clips if we reach the upper limit.
            if (count >= MAX_CLIPS_COUNT_FOR_BULK) return EMPTY;
            // Trim pageSize to not load more than our limit.
            const pageSize = Math.min(
              LIST_ALL_CLIPS_PAGE_SIZE, MAX_CLIPS_COUNT_FOR_BULK - count);
            // Make the next page API call.
            return this.assetService.searchClips(
              clipbinName, undefined, response.nextPageToken,
              pageSize);
          }),
          // Extract clips from each page response.
          concatMap(response => {
            if (isErrorResponse(response)) return [];
            // Local side-effect for failsafe limit.
            count += response.assets.length;
            return response.assets;
          }),
        );
    }

    updateClipBinSharedLinkTtlOwners(clipbinName: string, ttl: number, owners?: string[]) {
      const updateData: Partial<IASClipBinShareLinkData> = {
        ttl,
        expireTime: this.calculateExpireTime(ttl),
      };
      if (owners) {
        updateData.owners = owners;
      }
      const updateOperation = this.updateClipBinShareLink(clipbinName, updateData);
      updateOperation.then(() => firstValueFrom(this.publishService.publishMessage(clipbinName, this.origin, ttl)));
      return updateOperation;
    }

    async updateClipBinSharedLinkTtlById(id: string, ttl: number) {
      const clipbinName = await this.getClipbinNameForClipbinShareLink(id);
      this.updateClipBinSharedLinkTtlOwners(clipbinName, ttl);
    }

    async getClipbinNameForClipbinShareLink(id: string) {
      const ref = doc(this.getDB(), 'ias-clipbin-share-link', id);
      const document = await getDoc(ref);
      const documentData = document.data() as IASClipBinShareLinkData;
      return documentData.clipBinName;
    }

    updateClipBinSharedLinksList(clipbinName: string, clipSharedLinks: SharedLink[]) {
      return this.updateClipBinShareLink(clipbinName, { clipSharedLinks });
    }

    updateClipBinSharedLinkTitle(clipbinName: string, clipBinTitle: string) {
      return this.updateClipBinShareLink(clipbinName, {clipBinTitle});
    }

    private queryIASClipBinShareLink(constraints: QueryFilterConstraint[]) {
      return this.queryDocument('ias-clipbin-share-link', constraints)
        .pipe(
          map(documents =>
            documents.map(document => {
              return { ...document.data(), documentId: document.id } as IASClipBinShareLinkData;
            }))
        );
    }

    private queryDocument(collection: string, constraints: QueryFilterConstraint[]) {
      return this.firebaseResolver.queryCollectionWithoutLimitSize(collection, constraints);
    }

    private async updatePartialValueInDocuments(collection: string, constraints: QueryFieldFilterConstraint[], partialValue: object) {
      await this.firebaseResolver.updatePartialValueBatchMode(collection, constraints, partialValue);
    }

    async revokeClipbinShareLinkById(id:string) {
      const ref = doc(this.getDB(), 'ias-clipbin-share-link', id);
      await deleteDoc(ref);
    }

  private getDB() {
    return this.firebaseResolver.getFirestore() as Firestore;
  }

  /**
   * Update the clip link and share with properties.
   *
   * @param asset The clip asset.
   * @returns An observable that emits a value if the clip link or share is updated successfully, or an error otherwise.
   */
  updateClipLinkShared(asset: Clip): Observable<void> {
    const clipBinsId = encodeURIComponent(this.clipbinStorageService.clipBinsId ?? '');

    // Create a new link from clip
    return this.createClipShareWithProperties(asset, {})
    .pipe(
      concatMap((clip) => {
          // Retrieve clipbins with all clips releated
          return this.retrieveIASClipBinShareLink(clipBinsId ?? '', true)
        .pipe(
          take(1),
          concatMap((clipbins) => {
            const [ clipsIntoClipbin ] = clipbins;

            if(clipsIntoClipbin?.clipSharedLinks) {
            // Get the clip index
            const indexClipBinShared = clipsIntoClipbin.clipSharedLinks?.findIndex(clipShared => clipShared.clipName === asset.name) ?? 0;
              // Store the old link to be revoke
              const { assetName:linkToRevoke } = clipsIntoClipbin.clipSharedLinks[indexClipBinShared];

              // Update the properties from the new clip created
              clipsIntoClipbin.clipSharedLinks[indexClipBinShared].link = clip.link;
              clipsIntoClipbin.clipSharedLinks[indexClipBinShared].assetName = clip.assetName;
              return of({clipSharedLinks: clipsIntoClipbin.clipSharedLinks, linkToRevoke});
            }

            return EMPTY;
          }),
          concatMap(({ clipSharedLinks, linkToRevoke }) => {
              // Update firebase with the clips list updated
              this.updateClipBinSharedLinksList(clipBinsId ?? '', clipSharedLinks as unknown as SharedLink[]);
              return of(linkToRevoke);
            }
          ),
          // Revoke the old clip link
          concatMap((linkToRevoke) => this.sharedLinkService.revokeAll([linkToRevoke])),
          concatMap(() => EMPTY)
        );
      })
    );
  }
}
