<mat-form-field
    appearance="outline"
    class="search-bar"
    subscriptSizing="dynamic"
    [class.with-suggestions]="auto.isOpen"
>
  <mat-icon matPrefix class="search__icon--wrapper">search</mat-icon>

  <mat-chip-grid
      #chipList
      aria-label="Search labels"
      (click)="focus(false)"
      >
    <!-- Invisible chip to calculate dimensions one chip takes in the DOM. -->
    <mat-chip-row #sizingHelper class="special-chip sizing-helper">
      <span>+00</span>
    </mat-chip-row>

    <mat-chip-row
        *ngFor="let chip of visibleChips; let index = index;"
        [removable]="true"
        [disableRipple]="true"
        [class.invisible]="index === chipEditIndex"
        (click)="focus(true)"
        (dblclick)="openChipEditor(index)"
        (removed)="removeChip(index)">
      {{hideParens(chip)}}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip-row>

    <mat-chip-row
        *ngIf="hiddenChipsCount > 0"
        (click)="updateLayout(true)"
        class="special-chip chip-counter"
        [removable]="false"
        [disableRipple]="true"
    >
      <span>+{{hiddenChipsCount}}</span>
    </mat-chip-row>

    <div class="inputs" [style.min-width.px]="getMinInputWidth()">
      <!--
      Removed this focus from the input, so all the focusing is managed by the event stream.
      Made many tests and its working normally.
      -->
      <!-- (focus)="updateLayout(true)" -->
      <input
          #input
          #trigger="matAutocompleteTrigger"
          (blur)="onInputBlur($event)"
          [matAutocomplete]="auto"
          [matAutocompleteDisabled]="suggestionsDisabled"
          [matChipInputFor]="chipList"
          [placeholder]="getPlaceholderText()"
          maxlength="300"
          aria-label="Search"/>
    </div>
  </mat-chip-grid>

  <!-- Clear icon (hidden on small screens) -->
  <button
      *ngIf="!isEmpty() && !compact"
      class="clear-button"
      mat-icon-button
      matSuffix
      aria-label="Clear"
      matTooltip="Clear"
      mam-firebase-ga="Clear search input"
      (click)="clearClicked()"
  >
    <mat-icon>close</mat-icon>
  </button>

  <!-- Search mode dropdown selector -->
  <ng-container matSuffix>
    <ng-container *ngIf="searchType === SearchType.VOD && searchMode">
      <!-- Use a dropdown on large screens -->
      <ng-container *ngIf="!compact">
        <mat-divider *ngIf="!isEmpty()" [vertical]="true"></mat-divider>
        <button mat-button
            class="search-mode-button"
            [matMenuTriggerFor]="menu"
            [class.active]="menuTrigger.menuOpen"
            #menuTrigger="matMenuTrigger"
            mam-firebase-ga="Open search mode selector via dropdown">
          <span>{{formatSearchMode(searchMode)}}</span>
          <mat-icon class="arrow">arrow_drop_down</mat-icon>
        </button>
      </ng-container>

      <!-- Use an icon on small screens -->
      <button
          *ngIf="compact"
          mat-icon-button
          matSuffix
          aria-label="Search mode"
          matTooltip="Search mode"
          [matMenuTriggerFor]="menu"
          mam-firebase-ga="Open search mode selector via icon">
        <mat-icon>filter_list</mat-icon>
      </button>

      <mat-menu #menu="matMenu" class="menu-offset-down">
        <div class="mat-menu-title result-type__title">RESULT TYPE</div>
        <button
            *ngFor="let mode of searchModeChoices"
            mat-menu-item
            class="result-type__menu-item-spacing"
            (click)="changeSearchMode(mode)"
            mam-firebase-ga="Select search mode"
            [mam-firebase-resource]="mode">
          <mat-icon [style.opacity]="mode === searchMode ? 1 : 0">done</mat-icon>
          <span>{{formatSearchMode(mode)}}</span>
        </button>
      </mat-menu>
    </ng-container>
  </ng-container>

  <mat-autocomplete
      #auto="matAutocomplete"
      [autoActiveFirstOption]="false"
      [displayWith]="getSuggestionText"
      (optionActivated)="updateInputWithActiveOption()"
      (optionSelected)="onOptionSelected($event.option.value)">
    <mat-option
        *ngFor="let suggestion of suggestions; trackBy: trackSuggestion; let index = index"
        [value]="suggestion"
        tabindex="0"
        >
      <ng-container [ngSwitch]="getSuggestionType(suggestion)">
        <mat-icon *ngSwitchCase="suggestionType.HISTORY">history</mat-icon>
        <mat-icon *ngSwitchCase="suggestionType.LABEL">label</mat-icon>
        <mat-icon *ngSwitchCase="suggestionType.QUERY">search</mat-icon>
      </ng-container>
      <span>
        <span
            *ngFor="let word of getWordsStyles(hideParens(suggestion.text))"
            [class.bold]="word.matches"
            [class.light]="!word.matches">
          {{word.value}}
        </span>
      </span>
      <button
          *ngIf="suggestion.isFromHistory"
          mat-icon-button
          mam-firebase-ga="Remove suggestion from history"
          (click)="removeFromHistory(suggestion, $event)"
          aria-label="Remove from history"
          matTooltip="Remove from history"
          class="gmat-button">
        <mat-icon>clear</mat-icon>
      </button>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
