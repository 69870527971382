import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SnackBarService } from 'services/snackbar_service';

@Component({
    selector: 'mam-url-copy',
    templateUrl: './url_copy.component.html',
    styleUrl: './url_copy.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UrlCopy {
  @Input() title: string = '';

  @Input() url: string = '';

  @Input() hash: string = '';

  @Input() placeholder: string = 'Loading...';

  @Input() inputLabel: string = 'URL to copy';

  @Input() copyMessage: string = 'URL copied to clipboard.';

  @Input() buttonAnalisysLabel: string = 'URL to copy';

  constructor(private snackbar: SnackBarService) {}

  snackbarClipboardCopy() {
    this.snackbar.message(this.copyMessage);
  }
}
