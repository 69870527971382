import {Injectable} from '@angular/core';
import {DateTime} from 'luxon';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, concatMap, tap} from 'rxjs/operators';

import { ClipBinsInfoService } from 'clip_bins/services/clipbins_info.service';
import {CompReelInfo} from 'models';

import {ErrorResponse} from '../error_service/error_response';
import {ErrorService} from '../error_service/error_service';
import {StatusCode} from '../error_service/status_code';

import {ResourceChange} from './api_client.module';
import {Clip} from './asset_service';
import {BinApiService, BinListFilter} from './bin_api.service';

/** Bin resource change */
export type BinsChange = ResourceChange<Bin>;

/** Serves bins */
@Injectable({providedIn: 'root'})
export class BinService {
  clipBins: BinWithClips[] = [];

  /** Emits when any list of clipbins should be refreshed. */
  readonly binsUpdated$ = new BehaviorSubject<BinsChange|undefined>(undefined);

  constructor(
      private readonly clipBinsInfoService: ClipBinsInfoService,
      private readonly binApiService: BinApiService,
      private readonly errorService: ErrorService,
  ) {}

  /** Observable may throw */
  create(title: string): Observable<Bin|null> {
    return this.binApiService.create(title).pipe(tap(bin => {
        this.binsUpdated$.next({type: 'CREATE', item: bin});
     }),
      concatMap((bin) => {
        this.clipBinsInfoService.createIASClipBins({
          id: bin.name,
          title: bin.title
        });
        return of(bin);
      }),
    );
  }

  delete(name: string): Observable<null> {
    return this.binApiService.delete(name).pipe(tap(() => {
      this.binsUpdated$.next({type: 'DELETE', name});
    }),
    concatMap(() => {
      this.clipBinsInfoService.inactiveStatusIASClipBins(name);
      return of(null);
    })
  );
  }

  rename(name: string, title: string): Observable<Bin> {
    return this.binApiService.rename(name, title).pipe(tap(bin => {
        this.binsUpdated$.next({type: 'UPDATE', item: bin});
    }),
      concatMap((bin) => {
        this.clipBinsInfoService.updateTitle(name, title);
        return of(bin);
      })
    );
  }

  listWithAssets(pageToken?: string, pageSize = 20):
      Observable<ListWithAssetsResponse|null> {
    return this.binApiService.listWithAssets(pageToken, pageSize)
        .pipe(
            this.errorService.retryLong(),
            catchError(error => {
              this.errorService.handle(error);
              return of(null);
            }),
        );
  }

  getBin(name: string): Observable<Bin|ErrorResponse> {
    return this.binApiService.getBin(name).pipe(
        this.errorService.retryLong([StatusCode.NOT_FOUND]),
        this.errorService.catchError(),
    );
  }

  list(filters: BinListFilter, pageToken?: string, pageSize = 40):
      Observable<ListResponse|null> {
    return this.binApiService.list(filters, pageToken, pageSize)
        .pipe(
            this.errorService.retryShort(),
            catchError(error => {
              this.errorService.handle(error);
              return of(null);
            }),
        );
  }

  generateCompReel(
      binLabel: string, exportFolder: string, filename?: string,
      scratchFolder?: string): Observable<Bin|ErrorResponse> {
    return this.binApiService
        .generateCompReel(binLabel, exportFolder, filename, scratchFolder)
        .pipe(this.errorService.catchError());
  }

  listExportCompReels(
      userQuery: string, pageSize: number, pageToken?: string,
      date?: DateTime) {
    return this.binApiService
        .listExportCompReels(userQuery, pageSize, pageToken, date)
        .pipe(
            this.errorService.retryLong(),
            this.errorService.catchError(),
        );
  }
}

/** Represents clip bins */
export interface Bin {
  name: string;
  title: string;
  createTime: number;
  /**
   * A number from `"0"` to `"100"`, or `"100+"` when the count is not defined
   * due to a backend platform limitation, see http://b/177024401.
   */
  assetCount: string;
  compReelInfo?: CompReelInfo;
}

/** Represents a clip bin with a few of its clips */
export interface BinWithClips extends Bin {
  clips: Clip[];
}

/** Represents the ListResponse */
export interface ListResponse {
  bins: Bin[];
  nextPageToken?: string;
}

/** Represents the ListWithAssetsResponse */
export interface ListWithAssetsResponse {
  binsWithAssets: BinWithClips[];
  nextPageToken?: string;
}
