import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';

import { assertTruthy } from 'asserts/asserts';

import { FeatureFlagService } from '../feature_flag/feature_flag_service';
import { Clip } from '../services/asset_service';
import { BinWithClips } from '../services/bin.service';
import { DeleteBinDialog } from '../shared/delete_bin_dialog';
import { GetLinkForBinDialog } from '../shared/get_link_for_bin_dialog';
import { RenameBinDialog } from '../shared/rename_bin_dialog';

/**
 * Clickable clip bin card with up to 3 clips previews.
 */
@Component({
  selector: 'mam-clip-bin-preview',
  templateUrl: './clip-bin-preview.ng.html',
  styleUrls: ['./clip-bin-preview.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ClipBinPreview implements OnInit, OnDestroy {
  @Input() bin!: BinWithClips;

  /** True when layout switches to SMALL. */
  isSmallScreen = false;

  /** True when layout switches to MID. */
  isMidScreen = false;

  /** True when layout switches to Large. */
  isLargeScreen = false;

  /** True when layout switches to SMALL Landscape mode. */
  isSmallScreenLandScape = false;

  private readonly destroyed$ = new ReplaySubject<void>(1);

  @Input() sharingAdditionalProperties?: Record<string, string>;

  enableShareClipBin = this.featureFlag.featureOn('enable-sharing-clipbin-from-homepage');

  constructor(private readonly dialog: MatDialog, private readonly featureFlag: FeatureFlagService) { }

  ngOnInit() {
    assertTruthy(this.bin, '<mam-clip-bin-preview> requires property "bin"');
  }

  trackClip(index: number, clip: Clip) {
    return clip.name;
  }

  openRenameBin(title: string, name: string) {
    this.dialog.open(RenameBinDialog, {
      ...RenameBinDialog.dialogOptions,
      data: { title, name },
    });
  }

  openDeleteBin(name: string) {
    this.dialog.open(DeleteBinDialog, {
      ...DeleteBinDialog.dialogOptions,
      data: { name },
    });
  }


  ngOnDestroy() {
    // Unsubscribes all pending subscriptions.
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  openShareBin() {
      this.dialog.open(
        GetLinkForBinDialog, GetLinkForBinDialog.getDialogOptions({
          bin: this.bin,
          additionalProperties: this.sharingAdditionalProperties,
        }));
  }
}
