<ng-container *ngIf="{
  isFullScreen: playerFullscreenService.isFullScreen$ | async,
  isControlsShown: playerDetailsService.isControlsShown$ | async,
  isAssetTitleShown: playerDetailsService.isAssetTitleShown$ | async,
} as vars">

<div #playerArea class="player-area" [hidden]="!isAssetLoaded">
  <mam-shaka-player
      #shakaPlayer
      [hidden]="seekOnHoverTime != null"
      (bufferUpdate)="onBufferUpdate($event)"
      (trackIndexChange)="onShakaTrackChange($event)"
      (timeUpdate)="onTimeUpdate()">
  </mam-shaka-player>

  <mam-asset-thumbnail
      *ngIf="isAssetLoaded"
      [time]="seekOnHoverTime"
      [hidden]="seekOnHoverTime == null || !isThumbnailPlayerActive()"
      [asset]="asset">
  </mam-asset-thumbnail>

  <mam-video-player
      #videoSeek
      [hidden]="seekOnHoverTime == null || isThumbnailPlayerActive()"
      preload="metadata">
  </mam-video-player>

  <div class="overlay" [style.opacity]="playback.isFlashVisible ? '1' : '0'">
    <div class="overlay-container">
      <mat-icon>{{getCurrentPlaybackSpeedIcon()}}</mat-icon>
      <div class="overlay-rate">{{getCurrentPlaybackSpeed(true)}}x</div>
    </div>
  </div>

  <mam-player-overlay-controls
      #overlayControls
      *ngIf="isIos || isAndroid || ((isLandscape$ | async) && (isSmallScreen$ | async)) || smallControls"
      [isPaused]="isPaused()"
      (overlayControlEvent)="handleOverLayControlEvent($event)"
      class="player-overlay-controls"
  />
</div>

<!-- Placeholder when the asset has no rendition or is loading -->
<div class="thumbnail-placeholder" *ngIf="!isAssetLoaded">
  <mam-asset-thumbnail></mam-asset-thumbnail>
</div>

<div
  class="title-area"
  *ngIf="vars.isAssetTitleShown"
  [class.hidden]="isClipCreation">
  {{asset?.title}}
</div>

<div
    class="controls-area"
    [class.compact]="compact"
    [class.disabled]="!isAssetLoaded || !getTimelineDuration()"
    [class.hidden]="!vars.isControlsShown && !isClipCreation">
  <div #timelineArea>
    <mam-timeline
      mam-firebase-ga="Details Timeline Seek"
      [mam-firebase-type]="AnalyticsEventType.MOUSE_ENTER_LEAVE"
      [mam-firebase-resource]="asset?.name"
      [querySegment]="querySegment"
      [userQuery]="userQuery"
      [keywordResults]="keywordResults"
      [selectedKeyword]="selectedKeyword"
      [duration]="getTimelineDuration()"
      [mainTime]="getElapsed(asset)"
      [cutDownSegments]="cutDownSegments"
      [startTimestamp]="getStartTimestamp()"
      [live]="isLiveEdgeActive"
      [disabledMarking]="!enabledMarking"
      [disabledShortcuts]="disabledShortcuts"
      [bufferSegments]="bufferSegments"
      (clickTimeline)="onClickTimeline($event)"
      (seekTimeline)="onSeekTimeline($event)"
      (shortcutEvent)="onShortcutEvent($event)"
      (marking)="onMarking($event)">
  </mam-timeline>
</div>

  <div class="controls-block" #controlsBlock>
    <div class="playback-controls">
      <!-- Play / Pause -->
      <button
          mat-icon-button
          class="gmat-button playback-toggle"
          [attr.aria-label]="isPaused() ? 'Play video' : 'Pause video'"
          mam-firebase-ga="Toggle play/pause button"
          [mam-firebase-resource]="asset?.name"
          (click)="togglePlayPause()">
          <mat-icon>{{isPaused() ? 'play_arrow' : 'pause'}}</mat-icon>
      </button>

      <!-- Playback Speed selector -->
      <button
          mat-icon-button
          class="gmat-button playbacks-list-toggle"
          [class.hidden]="smallestControls && showClipCreation"
          [class.cut-down-mode]="cutDownMode"
          [matMenuTriggerFor]="playbackMenu"
          aria-label="Select playback speed"
          mam-firebase-ga="Toggle playback speed selector"
          [mam-firebase-resource]="asset?.name">
          <div class="playback-number playback-times">
            <div class="rate-text">{{playback.keyboardRange[playback.keyboardIndex]}}x</div>
          </div>
      </button>
      <mat-menu #playbackMenu="matMenu" yPosition="above">
        <ng-template matMenuContent>
          <div class="mat-menu-title">PLAYBACK SPEED</div>
          <button
              mat-menu-item
              *ngFor="let rate of playback.dropdownRange;"
              mam-firebase-ga="Select a playback speed"
              [mam-firebase-resource]="asset?.name"
              (click)="onSelectBaseSpeed(rate)">
              <mat-icon [style.opacity]="rate === playback.selectedSpeed ? 1 : 0">
                done
              </mat-icon>
              <span>{{rate === 1 ? "Normal" : rate + 'x'}}</span>
          </button>
        </ng-template>
      </mat-menu>

      <!-- Audio track selector -->
      <button
          mat-icon-button
          class="gmat-button tracks-list-toggle"
          [matMenuTriggerFor]="menu"
          [disabled]="!tracks.length"
          aria-label="Select audio track"
          mam-firebase-ga="Toggle audio track selector"
          [mam-firebase-resource]="asset?.name">
        <mat-icon>audiotrack</mat-icon>
        <div *ngIf="tracks.length" [@fade] class="icon-track-number">
          <div
              #trackCropper
              class="icon-track-number-cropper"
              [style.bottom.px]="selectedTrackIndex * trackCropperLineHeight">
            <div *ngFor="let track of tracks; index as index;">
              {{index + 1}}
            </div>
          </div>
        </div>
      </button>
      <mat-menu #menu="matMenu" yPosition="above">
        <ng-template matMenuContent>
          <div class="mat-menu-title">AUDIO TRACK</div>
          <button
              mat-menu-item
              *ngFor="let track of tracks; index as index;"
              mam-firebase-ga="Select an audio track"
              [mam-firebase-resource]="asset?.name"
              [mam-firebase-index]="index"
              (click)="changeAudioTrack(track)">
            <mat-icon [style.opacity]="index === selectedTrackIndex ? 1 : 0">
              done
            </mat-icon>
            <span>Audio track {{index + 1}}</span>
          </button>
        </ng-template>
      </mat-menu>

      <mam-volume-button
          [volume]="getVolume()"
          [disabledShortcuts]="disabledShortcuts"
          (volumeChange)="setVolume($event.value, $event.sliding)"
          [volumeSliderHidden]="volumeSliderHidden"
          (volumeSliderHiddenChange)="setVolumeSliderHidden($event)">
      </mam-volume-button>

      <!-- Live indicator -->
      <button
          class="live-indicator"
          *ngIf="!cutDownMode && asset?.isLive"
          (click)="onLiveIndicatorClicked()"
          [disabled]="!isLiveActive()"
          [class.active]="isLiveEdgeActive"
          mam-firebase-ga="Toggle live indicator"
          [mam-firebase-resource]="asset?.name">
        <mat-icon>fiber_manual_record</mat-icon>
        <span class="live-indicator-message">LIVE</span>
      </button>

      <!-- Timing -->
      <div
          class="timer "
          *ngIf="isAssetLoaded"
          [class.hidden]="isClipCreation"
          [@fade]>
        <span class="elapsed">
          {{getElapsed(asset) | durationOrTime:getStartTimestamp():'HH:mm:ss'}}
        </span>
        <span
            *ngIf="getTimelineDuration() && !isLiveActive()">
            <!-- Separator between elapsed and duration is different depending
            on whether we display relative durations or absolute times. -->
            {{getStartTimestamp() != null ? '-' : '/'}}
            {{getTimelineDuration() | durationOrTime:getStartTimestamp():'HH:mm:ss'}}
        </span>
      </div>
      <mam-time-format-selection
          class="timer-selector"
          *ngIf="isAssetLoaded && asset"
          [class.hidden]="isClipCreation || (smallestControls && asset.isLive)"
          matTooltip="Select time formatting"
          [asset]="asset"
          (formatChanged)="selectedTimeFormat = $event"
          [@fade]>
      </mam-time-format-selection>

      <!-- Replay/forward video controls section -->
      <div class="replay-forward-controls"
        *ngIf="!isIos && !isAndroid && !((isLandscape$ | async) && (isSmallScreen$ | async)) && !smallControls">
        <!-- Seek interval selector -->
        <button
            mat-icon-button
            class="gmat-button seek-intervals-list-toggle"
            [class.cut-down-mode]="cutDownMode"
            [matMenuTriggerFor]="seekIntervalsMenu"
            aria-label="Select video seek interval"
            mam-firebase-ga="Toggle video seek interval selector"
            [mam-firebase-resource]="asset?.name">
          <div class="playback-number">
            <div class="seek-text">{{playback.selectedSeekInterval}}s</div>
          </div>
        </button>
        <mat-menu #seekIntervalsMenu="matMenu" yPosition="above">
          <ng-template matMenuContent>
            <div class="mat-menu-title">SEEK INTERVAL</div>
            <button
                mat-menu-item
                *ngFor="let seekInterval of playback.seekIntervalsRange;"
                mam-firebase-ga="Select video seek interval"
                [mam-firebase-resource]="asset?.name"
                (click)="onSelectSeekInterval(seekInterval)">
              <mat-icon [style.opacity]="seekInterval === playback.selectedSeekInterval ? 1 : 0">
                done
              </mat-icon>
              <span>{{seekInterval + 's'}}</span>
            </button>
          </ng-template>
        </mat-menu>

        <!-- Replay video button -->
        <button
            mat-icon-button
            class="gmat-button replay-toggle"
            aria-label="Replay video"
            mam-firebase-ga="Toggle replay video button"
            [mam-firebase-resource]="asset?.name"
            (click)="movePlayheadSeconds(-playback.selectedSeekInterval)">
            <mat-icon>{{'replay_' + playback.selectedSeekInterval}}</mat-icon>
        </button>

        <!-- Forward video button -->
        <button
            mat-icon-button
            class="gmat-button forward-toggle"
            aria-label="Forward video"
            mam-firebase-ga="Toggle forward video button"
            [mam-firebase-resource]="asset?.name"
            (click)="movePlayheadSeconds(playback.selectedSeekInterval)">
            <mat-icon>{{'forward_' + playback.selectedSeekInterval}}</mat-icon>
        </button>
      </div>
    </div>

    <!-- Clip or cut-down infos and add button -->
    <div
        class="clip-container"
        [class.disabled]="clipMarking == null"
        [class.hidden]="!isClipCreation && !cutDownMode"
        *ngIf="!disabledMarking">

      <!-- Selection -->
      <div
          class="clip-info clip-selection"
          [class.hidden]="clipSelectionControls">
        <div class="clip-info-title">
          Selection
        </div>
        <div class="clip-info-value" *ngIf="clipMarking != null; else noClipSelection">
          {{(clipMarking.markIn | durationOrTime:getStartTimestamp():'HH:mm:ss')}}
          -
          {{(clipMarking.markOut | durationOrTime:getStartTimestamp():'HH:mm:ss')}}
        </div>
        <ng-template #noClipSelection>
          <div class="clip-info-value">{{(0 | duration)}} - {{(0 | duration)}}</div>
        </ng-template>
      </div>

      <!-- Duration -->
      <div
        class="clip-info clip-duration"
        [class.hidden]="clipDurationControls">
        <div class="clip-info-title">
          Selection duration
        </div>
        <div
            class="clip-info-value"
            *ngIf="clipMarking != null; else noClipDuration">
          {{((clipMarking.markOut - clipMarking.markIn) | duration)}}
        </div>
        <ng-template #noClipDuration>
          <div class="clip-info-value">{{0 | duration}}</div>
        </ng-template>
      </div>

      <!-- Add clip button (when not in cutDown mode) -->
      <button
            *ngIf="!cutDownMode; else showConfirmCutDown"
            (click)="onAddClipClicked()"
            class="add-clip-button"
            [class.mat-icon-button]="titledAddClipControl"
            [class.small-controls]="titledAddClipControl"
            [class.with-markings]="clipMarking"
            [matTooltip]="titledAddClipControl ? (clipMarking ? 'Add clip' : 'Add full title') : ''"
            [disabled]="disabledAddClip || (!clipMarking && isLiveActive())"
            mam-firebase-ga="Add {{clipMarking ? 'clip' : 'full title'}} from details view"
            [mam-firebase-resource]="asset?.name"
            mat-flat-button>
        <mat-icon>add</mat-icon>
        <ng-container *ngIf="!titledAddClipControl">
          {{clipMarking ? 'Add clip' : 'Add full title'}}
        </ng-container>
      </button>
    </div>

    <!-- Clip Creation and Full screen button -->
    <div class="edit-fullscreen-container">

      <!-- Confirm cut down -->
      <ng-template #showConfirmCutDown>
        <div
            matTooltip="Cutdown with the same offsets already exists"
            [matTooltipDisabled]="!clipMarking || canConfirmCutdown(clipMarking)">

          <button
              *ngIf="!smallControls; else compactConfirmCutDown"
              [disabled]="!canConfirmCutdown(clipMarking)"
              (click)="confirmCutDown.emit(clipMarking)"
              class="confirm-cut-down"
              mam-firebase-ga="Confirm cut-down from details view"
              [mam-firebase-resource]="asset?.name"
              mat-stroked-button>
            Confirm
          </button>

          <ng-template #compactConfirmCutDown>
            <button
                [disabled]="!canConfirmCutdown(clipMarking)"
                mat-icon-button
                class="confirm-cut-down"
                matTooltip="Confirm cutdown"
                mam-firebase-ga="Confirm cut-down from details view"
                [mam-firebase-resource]="asset?.name"
                (click)="confirmCutDown.emit(clipMarking)"
                aria-label="Confirm cutdown">
              <mat-icon>content_cut</mat-icon>
            </button>
          </ng-template>
        </div>
      </ng-template>

      <!-- Clip Creation -->
      <button *ngIf="showClipCreation"
            mat-icon-button
            class="gmat-button clip-creation-button"
            [attr.aria-label]="isClipCreation ? 'Exit Clip Creation' : 'Enter Clip Creation'"
            mam-firebase-ga="Toggle clip creation button"
            [mam-firebase-resource]="asset?.name"
            (click)="toggleClipCreation()">
          <mat-icon>{{isClipCreation ? 'close' : 'content_cut'}}</mat-icon>
      </button>

      <!-- Fullscreen -->
      <ng-container
          *ngTemplateOutlet="fullScreenButton; context: {$implicit: 'gmat-button fullscreen-toggle'}">
      </ng-container>

      <!-- Native Fullscreen -->
      <ng-container *ngIf="vars.isFullScreen">
        <ng-container
            *ngTemplateOutlet="nativeFullScreenButton; context: {$implicit: 'gmat-button fullscreen-toggle'}">
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!isIos && !isAndroid && !((isLandscape$ | async) && (isSmallScreen$ | async)) && !smallControls">
        <!-- Fullscreen -->
        <ng-container
            *ngTemplateOutlet="fullScreenButton; context: {$implicit: 'gmat-button fullscreen-desktop-toggle'}">
        </ng-container>

        <!-- Native Fullscreen -->
        <ng-container
            *ngTemplateOutlet="nativeFullScreenButton; context: {$implicit: 'gmat-button native-fullscreen-desktop-toggle'}">
        </ng-container>
      </ng-container>

    </div>
  </div>
</div>


<!-- Fullscreen button template -->
<ng-template #fullScreenButton let-buttonClass>
  <button
      mat-icon-button
      [ngClass]="buttonClass"
      [class.hidden]="cutDownMode"
      [attr.aria-label]="vars.isFullScreen ? 'Exit FullScreen' : 'Fullscreen'"
      mam-firebase-ga="Toggle fullscreen button"
      [mam-firebase-resource]="asset?.name"
      (click)="toggleFullScreen()">
    <mat-icon>{{vars.isFullScreen ? 'fullscreen_exit' : 'fullscreen'}}</mat-icon>
  </button>
</ng-template>

<!-- Native Fullscreen button template -->
<ng-template #nativeFullScreenButton let-buttonClass>
  <button
      mat-icon-button
      [ngClass]="buttonClass"
      [attr.aria-label]="vars.isFullScreen ? 'Exit FullScreen' : 'Fullscreen'"
      mam-firebase-ga="Open native fullscreen"
      [mam-firebase-resource]="asset?.name"
      *ngIf="vars.isFullScreen"
      (click)="openNativeFullScreen()">
    <mat-icon>open_in_full</mat-icon>
  </button>
</ng-template>

</ng-container>
