import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';

import {ClipbinStorageService} from 'services/storage/clip_bin_storage.service';

import {ClipLocalStorage} from '../models/storage.model';
import {BinWithClips} from '../services/bin.service';
import {SharedLinksService} from '../services/shared_links_service';
import {SnackBarService} from '../services/snackbar_service';
import {
  ClipBinExpirationDaysOption,
  SharedLinkClipBinService
} from '../shared_clipbin/services/shared_link_clipbin.service';

/** Input data to this dialog */
export interface GetLinkForBinDialogInputData {
  /** ClipBin shared. */
  bin: BinWithClips;
  /** Additional properties to add to the shared link. */
  additionalProperties?: Record<string, string>;
}

/** Dialog with generated shared link URL. */
@Component({
  selector: 'mam-get-link-for-bin-dialog',
  templateUrl: './get_link_for_bin_dialog.ng.html',
  styleUrls: ['./get_link_for_bin_dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GetLinkForBinDialog {
  static getDialogOptions(data: GetLinkForBinDialogInputData):
      MatDialogConfig<GetLinkForBinDialogInputData> {
    return {
      data,
      maxWidth: 640,
      width: '90%',
    };
  }

  selectedDaysOption: ClipBinExpirationDaysOption = this.sharedLinkClipBinService.clipbinDefaultExpirationDays;

  readonly expirationDaysOptions: ClipBinExpirationDaysOption[] = this.sharedLinkClipBinService.clipbinExpirationDaysOptions;


  private readonly encodedClipBinName: string;

  /** Generated URL to actually access the clip bin shared by this link. */
  url = '';

  isUpdatingExpiration = false;

  // Clips that have changed made in the current visit
  clipChanges: ClipLocalStorage[] = this.clipBinSharingStorage.clipsList.filter((el) => !el.initialLoad);

  constructor(
      readonly dialogRef: MatDialogRef<GetLinkForBinDialog>,
      private readonly sharedLinkClipBinService: SharedLinkClipBinService,
      private readonly sharedLinks: SharedLinksService,
      private readonly snackbar: SnackBarService,
      private readonly cdr: ChangeDetectorRef,
      private readonly clipBinSharingStorage: ClipbinStorageService,
      @Inject(MAT_DIALOG_DATA) readonly data: GetLinkForBinDialogInputData,
  ) {

    this.encodedClipBinName = encodeURIComponent(data.bin.name);
    const createCallback = () => {
      this.url = this.sharedLinks.getClipBinLinkUrl(this.encodedClipBinName);
      this.cdr.markForCheck();
    };
    this.sharedLinkClipBinService.createClipBinSharedLinkIfNotExist(
        data.bin,
        data.additionalProperties,
        this.selectedDaysOption,
        createCallback,
    );
  }

  selectDaysOption(selection: ClipBinExpirationDaysOption) {
    if (!this.url) {
      return;
    }
    if (selection === this.selectedDaysOption) {
      return;
    }

    this.isUpdatingExpiration = true;
    this.sharedLinkClipBinService.updateClipBinSharedLinkTtlOwners(this.encodedClipBinName, selection)
      .then(() => {
        this.isUpdatingExpiration = false;
        this.selectedDaysOption = selection;
        this.cdr.markForCheck();
        return;
      });
  }

  formatExpiration(expirationDays: ClipBinExpirationDaysOption) {
    switch (expirationDays) {
      case 1:
        return 'Link expires in 1 day';
      default:
        return `Link expires in ${expirationDays} days`;
    }
  }
}
