<h1 mat-dialog-title>Get link for {{data.asset.original && data.asset.original.duration.toFixed(0) !== data.asset.duration.toFixed(0) ? 'clip' : 'video'}}</h1>

<div mat-dialog-content class="dialog__get-link--spacing">
  <!-- URL and copy button -->
  <mam-url-copy
    [title]="data.asset.name"
    [url]="url"
    [hash]="originalHash"
    inputLabel="URL of the shared video"
    buttonAnalisysLabel="Copy Shared Link Url"
    copyMessage="URL copied to clipboard."
  ></mam-url-copy>

  <!-- Expiration time menu selection -->
  <button
      mat-button
      class="expiration-selector"
      [disabled]="!url || isUpdatingExpiration"
      [matMenuTriggerFor]="expirationsMenu"
      #menuTrigger="matMenuTrigger"
      mam-firebase-ga="Open expiration time picker"
      [mam-firebase-resource]="data.asset.name">
    <span class="expiration-selected">{{formatExpiration(selectedDaysOption)}}</span>
    <mat-icon class="arrow main__icon-arrow--size">arrow_drop_down</mat-icon>
  </button>

  <mat-menu #expirationsMenu="matMenu">
    <button
        *ngFor="let daysOption of expirationDaysOptions"
        mat-menu-item
        (click)="selectDaysOption(daysOption)"
        mam-firebase-ga="Select expiration time"
        mam-firebase-resource="{{daysOption}}">
      <mat-icon [class.invisible]="selectedDaysOption !== daysOption">
        done
      </mat-icon>
      <span>{{formatExpiration(daysOption)}}</span>
    </button>
  </mat-menu>
</div>

<div mat-dialog-actions class="dialog-get-link__actions--position">
  <button
    mat-button
    [mat-dialog-close]="selectedDaysOption"
    class="primary dialog__button--position"
    mam-firebase-ga="Close link sharing dialog"
    [mam-firebase-resource]="data.asset.name">
    Done
  </button>
</div>
