import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {BehaviorSubject, combineLatest, merge, Observable, ReplaySubject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, startWith, switchMap, takeUntil, tap} from 'rxjs/operators';

import {isErrorResponse} from '../error_service/error_response';
import { AnalyticsEventType, FirebaseAnalyticsService } from '../firebase/firebase_analytics_service';
import {Bin, BinService} from '../services/bin.service';
import {ClipbinsOwner} from '../services/bin_api.service';
import {SnackBarService} from '../services/snackbar_service';
import {StateService} from '../services/state_service';

/** Debounces keyboard search to lower API calls. */
const SEARCH_DEBOUNCE_MS = 500;

/**
 * Component that renders a clip bin list, allows for interactions like
 * filtering and selection the bins. Used as part of other components like
 * `MoveClipDialog` and `AddClipDialog`.
 */
@Component({
  selector: 'mam-clip-bin-search-panel',
  templateUrl: './clip_bin_search_panel.ng.html',
  styleUrls: ['./clip_bin_search_panel.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClipbinSearchPanel implements OnInit, OnDestroy {
  /**
   * isMultipleOptions is a boolean value from parent component, we use it to
   * decide whether displaying clip bins in checkbox view or radio view
   */
  @Input() isMultipleOptions = false;

  /** Optional name of a clipbin to preselect an display on top of others. */
  @Input() defaultBinNameSelected?: string;

  /** Emits when selecting or unselecting a clip bin in checkbox view */
  @Output() readonly selectedBinsChanged = new EventEmitter<string[]>();

  @ViewChild('scrollableView') scrollableView!: ElementRef<HTMLElement>;

  selectedBins = new Set<string>();

  bins$ = new ReplaySubject<Bin[]>(1);

  readonly searchControl = new UntypedFormControl();

  clipbinsOwner$ = new ReplaySubject<ClipbinsOwner>(1);

  constructor(
      private readonly binService: BinService,
      private readonly snackBar: SnackBarService,
      private readonly analyticsService: FirebaseAnalyticsService,
      state: StateService,
  ) {
    this.searchChanged$ = this.getSearchChanged();

    // Initialize clipbins owner to the one selected in the persistent panel.
    this.clipbinsOwner$.next(state.clipbinsOwner$.value);

    // Resets pagination and scrolls top when search value changes or clip bins
    // get updated
    merge(
        this.searchChanged$,
        this.binService.binsUpdated$,
        this.clipbinsOwner$,
        )
        .pipe(takeUntil(this.destroyed))
        .subscribe(() => {
          this.resetBinsPagination();
          this.scrollToTop();
        });
  }

  ngOnInit() {
    const defaultBinNameSelected = this.defaultBinNameSelected;
    if (defaultBinNameSelected) {
      // If a default clipbin name is provided, load the full clipbin. We load
      // it each time this dialog is opened to get an up-to-date assetCount.
      this.binService.getBin(defaultBinNameSelected)
          .pipe(takeUntil(this.destroyed))
          .subscribe((binResponse) => {
            if (!isErrorResponse(binResponse)) {
              this.defaultBinSelected$.next(binResponse);
              this.onChange(binResponse.name, true);
            } else {
              // Current clip bin cannot be loaded, uncheck it from the dialog
              // and trigger a clipbin refresh of the persistent panel which
              // will snackbar an error and unselect it.
              this.defaultBinSelected$.next(undefined);
              this.binService.binsUpdated$.next(undefined);
            }
          });
    } else {
      this.defaultBinSelected$.next(undefined);
    }

    combineLatest([
      this.searchChanged$,
      this.clipbinsOwner$,
      this.binService.binsUpdated$,
      this.binsNextPageChange$,
    ])
        .pipe(
            takeUntil(this.destroyed),
            tap(() => {
              this.isBinsLoading = true;
            }),
            switchMap(
              ([title, owner]) => {

                title && this.analyticsService.logEvent('Call API Search Clipbins', {
                  term: title,
                  eventType: AnalyticsEventType.API_CALL,
                });

                return this.binService.list(
                {title, owner}, this.binsNextPageToken);

              }),
            switchMap(response => {
              return this.defaultBinSelected$.pipe(
                  map(defaultBinSelected => ({response, defaultBinSelected})));
            }),
            )
        .subscribe(({response, defaultBinSelected}) => {
          this.isBinsLoading = false;
          if (!response) {
            this.snackBar.error('Clip bins could not be loaded');
            return;
          }

          const {bins, nextPageToken} = response;

          if (!nextPageToken) {
            this.listenToBinsNextPageChange = false;
          }

          this.cachedBins.push(...bins);
          let binsWithDefault = this.cachedBins;

          // If a default bin is provided, ensure that it is displayed on top of
          // the list of clipbins.
          if (defaultBinSelected && !this.searchControl.value) {
            binsWithDefault = [
              defaultBinSelected,
              ...binsWithDefault.filter(
                  bin => bin.name !== defaultBinSelected.name)
            ];
          }

          this.bins$.next(binsWithDefault);
          this.binsNextPageToken = nextPageToken;
        });
  }

  trackName(index: number, value: Bin) {
    return value.name;
  }

  onNearBottomForBinsArea() {
    // Only get triggered when API finishes
    // or it is not the last page result of the API results
    if (!this.isBinsLoading && this.listenToBinsNextPageChange) {
      this.binsNextPageChange$.next();
    }
  }

  onChange(clipbinName: string, checked: boolean) {
    if (!this.isMultipleOptions) {
      // Remove previously selected item if any for single-choice mode.
      this.selectedBins.clear();
    }

    if (checked) {
      this.selectedBins.add(clipbinName);
    } else {
      this.selectedBins.delete(clipbinName);
    }

    this.selectedBinsChanged.emit([...this.selectedBins]);
  }

  createBin() {
    const name = this.searchControl.value;
    this.binService.create(name).subscribe({
      next: () => {
        this.snackBar.message('Clip bin has been created successfully');
      },
      error: (error: unknown) => {
        this.snackBar.error('Clip bin could not be created.', undefined, error);
      }
    });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  private readonly destroyed = new ReplaySubject<void>(1);

  private cachedBins: Bin[] = [];

  private readonly binsNextPageChange$ = new BehaviorSubject<void>(undefined);

  private readonly searchChanged$: Observable<string>;

  private readonly defaultBinSelected$ = new ReplaySubject<Bin|undefined>(1);

  private binsNextPageToken?: string;

  private isBinsLoading = false;

  private listenToBinsNextPageChange = true;

  private resetBinsPagination() {
    this.cachedBins = [];
    this.binsNextPageToken = undefined;
    this.listenToBinsNextPageChange = true;
  }

  private getSearchChanged() {
    return this.searchControl.valueChanges.pipe(
        debounceTime(SEARCH_DEBOUNCE_MS),
        startWith(''),
        distinctUntilChanged(),
    );
  }

  private scrollToTop() {
    if (this.scrollableView) {
      this.scrollableView.nativeElement.scrollTop = 0;
    }
  }
}

/**
 * Helps pass selected/unselected clip bin information from child to parent
 * component.
 */
export interface ClipbinChangeEvent {
  checked: boolean;
  clipbinName: string;
}
