<!-- Trigger change detection when on of these changes. -->
<ng-container *ngIf="{
  pendingApproval: stagingService.pendingApproval$ | async,
  isEditing: stagingService.isEditing$ | async,
  selectedAssetSet: stagingService.selectedAssetSet$ | async,
  activeAssetSet: stagingService.activeItemSet$ | async
} as tplState">
    <ng-container *ngIf="assets &&
            tplState.pendingApproval &&
            tplState.isEditing != null &&
            tplState.selectedAssetSet &&
            tplState.activeAssetSet &&
            getSelectionInfo(assets, tplState.selectedAssetSet) as selectionInfo">


      <div class="actions-search-bar-container">
        <div class="action-bar-container">
          <div class="video-actions">
            <!-- (De)Select all toggle. -->
            <mat-checkbox color="primary" [disabled]="!assets.length || loading || tplState.isEditing" (change)="selectionInfo.areAll
                                  ? stagingService.select([])
                                  : stagingService.select(selectionInfo.selectableItems)"
              [checked]="selectionInfo.areAll" [indeterminate]="selectionInfo.indeterminate"
              [aria-label]="tableUtils.allCheckboxLabel(selectionInfo)"
              [matTooltip]="tableUtils.allCheckboxLabel(selectionInfo)" matTooltipPosition="above"
              mam-firebase-ga="Toggle selection for all assets in vod staging">
            </mat-checkbox>

            <!-- Actions for selected assets. -->
            <ng-container *ngIf="selectionInfo.areAny">
              <!-- Approve -->
              <button [disabled]="loading || tplState.isEditing"
                mat-icon-button
                class="approve-selected main__icon--size"
                aria-label="Approve selected assets"
                matTooltip="Approve selected assets"
                matTooltipPosition="above"
                mam-firebase-ga="Approve selected assets in vod staging"
                (click)="approve(selectionInfo.selectedItems)">
                <mat-icon class="material-icons-outlined">check_circle</mat-icon>
              </button>
              <!-- Add to clip bin -->
              <button [disabled]="loading || tplState.isEditing" mat-icon-button class="add-selected-to-bins main__icon--size"
                aria-label="Add selected assets to clip bins" matTooltip="Add selected assets to clip bins"
                matTooltipPosition="above" mam-firebase-ga="Add selected assets to clip bins in vod staging"
                (click)="addClipsToBins(selectionInfo.selectedItems)">
                <mat-icon class="material-icons-outlined">add_box</mat-icon>
              </button>
              <!-- Edit -->
              <button [disabled]="loading || tplState.isEditing" mat-icon-button class="edit-selected main__icon--size"
                aria-label="Edit selected assets" matTooltip="Edit selected assets" matTooltipPosition="above"
                mam-firebase-ga="Bulk edit in vod staging" (click)="edit(selectionInfo.selectedItems)">
                <mat-icon class="material-icons-outlined">edit</mat-icon>
              </button>
              <!-- Sync -->
              <button *featureFlagOn="'use-sync-metadata'" [disabled]="loading || tplState.isEditing"
                mat-icon-button class="sync-selected main__icon--size" aria-label="Sync selected assets"
                matTooltip="Sync selected assets" matTooltipPosition="above" mam-firebase-ga="Bulk sync in vod staging"
                (click)="syncMetadata(selectionInfo.selectedItems)">
                <mat-icon class="material-icons-outlined">cloud_sync</mat-icon>
              </button>
              <!-- On-prem TTL Extension -->
              <button [disabled]="loading || tplState.isEditing" mat-icon-button class="extend-ttl-selected main__icon--size"
                aria-label="Extend TTL on selected assets" matTooltip="Extend TTL on selected assets"
                matTooltipPosition="above" mam-firebase-ga="Extend TTL on selected assets in vod staging"
                (click)="extendAssetsTtl(selectionInfo.selectedItems)">
                <mat-icon class="material-icons-outlined">more_time</mat-icon>
              </button>
              <!-- On-prem purge -->
              <button [disabled]="loading || tplState.isEditing" mat-icon-button
                (click)="purgeAssets(selectionInfo.selectedItems)" class="purge-selected main__icon--size"
                aria-label="Delete on-prem files" matTooltip="Delete on-prem files" matTooltipPosition="above"
                mam-firebase-ga="Purge VoD staging selection">
                <mat-icon class="material-icons-outlined">folder_delete</mat-icon>
              </button>
              <!-- Delete -->
              <button [disabled]="loading || tplState.isEditing" mat-icon-button class="delete-selected main__icon--size"
                aria-label="Delete selected assets" matTooltip="Delete selected assets" matTooltipPosition="above"
                mam-firebase-ga="Delete selected assets in vod staging"
                (click)="deleteAssets(selectionInfo.selectedItems)">
                <mat-icon class="material-icons-outlined">delete</mat-icon>
              </button>
            </ng-container>
          </div>

          <!-- Refresh -->
          <div class="video-actions">
            <button
              [disabled]="loading || tplState.isEditing"
              class="refresh-button main__icon--size"
              mat-icon-button
              aria-label="Refresh the list"
              matTooltip="Refresh the list"
              matTooltipPosition="above"
              mam-firebase-ga="Refresh table in vod staging"
              (click)="refreshTable()">
              <mat-icon>refresh</mat-icon>
            </button>
          </div>
        </div>

        <!-- Search & Pagination -->
        <div class="search-bar-container">
          <mat-form-field
            appearance="outline"
            class="search-bar"
            [class.disabled]="tplState.isEditing">
            <mat-icon matPrefix>
              search</mat-icon>
            <input
              type="text"
              [readonly]="tplState.isEditing"
              matInput
              [formControl]="search"
              placeholder="Search assets"
              aria-label="Search assets"
              autocomplete="off">
            <button
              [disabled]="tplState.isEditing"
              *ngIf="search.value"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              mam-firebase-ga="Clear asset search query in vod staging"
              (click)="search.setValue('')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-paginator
            [disabled]="loading || tplState.isEditing"
            [length]="totalCount"
            [pageSize]="pageSize"
            [pageIndex]="currentPageIndex"
            (page)="changePage($event)"
            [pageSizeOptions]="PAGE_SIZE_OPTIONS"
            [hidePageSize]="!featureService.featureOn('use-vod-staging-page-size-selector')"
            class="vod-staging-paginator main__mat-paginator--border">
          </mat-paginator>
        </div>
      </div>

      <div class="table-box">
        <!-- Table -->
        <table
          class="non-expandable-table"
          mat-table
          [trackBy]="tableUtils.trackByName"
          [dataSource]="assets"
          matSort
          [matSortActive]="activeSort.active"
          [matSortDirection]="activeSort.direction"
          [matSortDisableClear]="true"
          (matSortChange)="onSortChanged($event)"
          [mamTable]="displayedColumns()"
          [class.has-selection]="selectionInfo.areAny"
        >

          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
            <th
              id="select-header"
              mat-header-cell
              *matHeaderCellDef
              mamCol
              draggable="false"
              [hideResizer]="true"
            >
            </th>
            <!-- Expand checkbox clickable area by also listening to td.click -->
            <td mat-cell *matCellDef="let asset" (click)="
                  $event.stopPropagation();
                  toggleSelection(asset, tplState.selectedAssetSet, $event.shiftKey)">
              <mat-checkbox *ngIf="canBeSelected(asset)" color="primary" [disabled]="loading || tplState.isEditing"
                [checked]="tplState.selectedAssetSet.has(asset.name)"
                [aria-label]="tableUtils.checkboxLabel(asset, tplState.selectedAssetSet)">
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- Title / File name Column -->
          <ng-container matColumnDef="title">
            <th
              id="title-header"
              mat-header-cell
              mat-sort-header
              *matHeaderCellDef
              mamCol
              [mamMinWidth]="80"
              draggable="false"
              (mamResetCols)="onResetCols()"
            >
              Title / File name
            </th>
            <td mat-cell *matCellDef="let asset" class="main-column">
              <div class="icon-with-text">
                <mam-asset-info-icon-button [asset]="asset" [icon]="getStatusIcon(asset)"
                  [showIconForExternalUsers]="true" [tooltip]="getStatusTooltip(asset)">
                </mam-asset-info-icon-button>
                <span [title]="asset.gcsLocationUrl">
                  <span matTooltipClass="mobile-tooltip" matTooltip="{{assetService.getAssetTitle(asset)}}"
                    matTooltipPosition="below">{{assetService.getAssetTitle(asset)}}</span>
                </span>
              </div>
            </td>
          </ng-container>

          <!-- Schema Column -->
          <ng-container matColumnDef="schema">
            <th
              id="schema-header"
              mat-header-cell
              *matHeaderCellDef
              mamCol="120px"
              [mamMinWidth]="110"
              draggable="true"
              (mamResetCols)="onResetCols()"
            >
              Schema name
            </th>
            <td mat-cell *matCellDef="let asset" [title]="assetService.getSchemaTitle(asset) | async">
              <span matTooltipClass="mobile-tooltip" matTooltip="{{assetService.getSchemaTitle(asset) | async}}"
                matTooltipPosition="below">{{assetService.getSchemaTitle(asset) |
                async}}</span>
            </td>
          </ng-container>

          <!-- Source Column -->
          <ng-container matColumnDef="source">
            <th
              id="source-header"
              mat-header-cell
              *matHeaderCellDef
              [hidden]="!showAssetsSource"
              mamCol="100px"
              [mamMinWidth]="80"
              draggable="true"
              (mamResetCols)="onResetCols()"
            >
              <mam-sort-col-btn
                [rows]="assets"
                [key]="'source'"
                colName="Source"
                (mamSortByField)="onSortByField($event)">
                Source
              </mam-sort-col-btn>
            </th>
            <td mat-cell *matCellDef="let asset" [title]="asset.source" [hidden]="!showAssetsSource">
              <span matTooltipClass="mobile-tooltip" matTooltip="{{asset.source}}" matTooltipPosition="below">
                {{asset.source}}
              </span>
            </td>
          </ng-container>

          <!-- Date Column -->
          <ng-container matColumnDef="date">
            <th
              id="date-header"
              mat-header-cell
              mat-sort-header
              *matHeaderCellDef
              mamCol="120px"
              [mamMinWidth]="80"
              draggable="true"
              (mamResetCols)="onResetCols()"
            >
              Date
            </th>
            <!-- e.g. Monday, June 15, 2015 at 21:03:01 GMT+01:00  -->
            <td mat-cell *matCellDef="let asset"
              [title]="(asset.createTime | tzdate:'EEEE, MMMM d, y, HH:mm:ss zzzz') || 'No date available'">
              <!-- e.g. Oct 25, 2021, 14:11-->
              <span matTooltipClass="mobile-tooltip" matTooltip="{{asset.createTime | tzdate:'MMM d, y, HH:mm'}}"
                matTooltipPosition="below">{{asset.createTime | tzdate:'MMM d, y, HH:mm'}}</span>
            </td>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <th
              id="status-header"
              mat-header-cell
              *matHeaderCellDef
              mamCol="100px"
              [mamMinWidth]="80"
              draggable="true"
              (mamResetCols)="onResetCols()"
            >
              <mam-multiselect-table-header headerName="Status" [options]="statusFilterOptions"
                (toggle)="onStatusFilterChanged($event)">
              </mam-multiselect-table-header>
            </th>
            <td mat-cell *matCellDef="let asset">
              <div class="icon-with-text status-column-icon">
                <ng-container *ngIf="formatStatus(asset) === 'Ready'">
                  <mat-icon
                    class="status-icon"
                    matTooltip="Ready"
                    matTooltipPosition="below">
                    offline_pin
                  </mat-icon>
                  <span>Ready</span>
                </ng-container>
                <ng-container *ngIf="formatStatus(asset) === 'Processing'">
                  <mat-icon
                    class="status-icon"
                    matTooltip="Processing"
                    matTooltipPosition="below">
                    pending</mat-icon>
                  <span>Processing</span>
                </ng-container>
                <ng-container *ngIf="formatStatus(asset) !== 'Processing' && formatStatus(asset) !== 'Ready'">
                  <span>{{formatStatus(asset)}}</span>
                </ng-container>
              </div>
            </td>
          </ng-container>

          <!-- Location Column -->
          <ng-container matColumnDef="location">
            <th
              id="location-header"
              mat-header-cell
              *matHeaderCellDef
              mamCol="80px"
              draggable="false"
            >
              Storage
            </th>
            <td mat-cell *matCellDef="let asset">
              <mam-asset-location-status *ngIf="!asset.isDeleted" [asset]="asset">
              </mam-asset-location-status>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns()"></tr>

          <tr mat-row *matRowDef="let asset; columns: displayedColumns();" mam-firebase-ga="Select asset in vod staging"
            [mam-firebase-resource]="asset.name" [class.selected]="tplState.selectedAssetSet.has(asset.name)"
            [class.active]="tplState.activeAssetSet.has(asset.name)" [class.approved]="asset.approved"
            [class.deleted]="asset.isDeleted" [class.error]="asset.hasError"
            (click)="selectOrActivate(asset, tplState.selectedAssetSet, $event.shiftKey)">
          </tr>
        </table>
      </div>

      <div *ngIf="assets.length === 0" class="empty-message">
        No assets found
      </div>
    </ng-container>
</ng-container>
