import { Inject, Injectable, NgZone } from '@angular/core';
import { DocumentReference, Firestore, QueryFieldFilterConstraint, Timestamp, where } from '@firebase/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ClipBinsInfo, ClipBinsItem } from "clip_bins/models/clip_bin.model";
import { LOCATION_ORIGIN } from 'services/shared_links_service';
import { TimezoneService } from 'services/timezone_service';

import { AuthService } from '../../auth/auth_service';
import { FirebaseResolver } from '../../firebase/firebase_resolver';


export const CLIPBINS_INFO_COLLECTION = 'ias-clipbins-info';

/**
 * A Service to manager the infos from clipbins storage on firestore
 */
@Injectable({
  providedIn: 'root'
})
export class ClipBinsInfoService {

    constructor(
        private readonly firebaseResolver: FirebaseResolver,
        private readonly timezone: TimezoneService,
        private readonly authService: AuthService,
        private readonly ngZone: NgZone,
        @Inject(LOCATION_ORIGIN) private readonly origin: string
    ) {}

    /**
     * Creates a new document in the Firebase ias-clipbins-info collection.
     *
     * @param data The data to be inserted into the document.
     * @returns A promise with the document reference if the insertion is successful, or false if not.
     */
    async createIASClipBins(data: Partial<ClipBinsInfo>): Promise<boolean | DocumentReference> {
        data.ownerId = this.authService.getUserEmail();
        data.ownerName = this.authService.getUserName();
        data.createdAt = Timestamp.now();
        data.isActive = true;
        data.domain = this.origin;

        return this.ngZone.runOutsideAngular(() => {
            return this.firebaseResolver.createFirestoreDoc(CLIPBINS_INFO_COLLECTION, data);
        });
    }

    getIASClipBins(clipbinName: string, isActive: boolean): Observable<ClipBinsInfo[]> {
        const constraints: QueryFieldFilterConstraint[] = [
            where('id', '==', clipbinName),
            where('domain', '==', this.origin),
            where('isActive', '==', isActive),
        ];

        return this.queryIASClipBins(constraints);
    }

    /**
     * Update or Create a new document on firebase with clips into clipbins
     */
    async updateClipsList(clipbinName: string, clips: ClipBinsItem[]) {
        const constraints: QueryFieldFilterConstraint[] = [
            where('id', '==', clipbinName),
            where('domain', '==', this.origin),
            where('isActive', '==', true)
        ];

        const partialValue: Partial<ClipBinsInfo> = {
            id: clipbinName,
            clips,
            updatedAt:  Timestamp.now(),
            updatedBy: this.authService.getUserEmail()
        };

        const docExist = await this.firebaseResolver.documentExist(CLIPBINS_INFO_COLLECTION, constraints);

        if(docExist) {
          return this.updatePartialValueInDocuments(CLIPBINS_INFO_COLLECTION, constraints, partialValue);
        }

        return this.createIASClipBins(partialValue);
    }

    /**
     * Updates the title of the clipbin with the given name.
     *
     * @param clipbinName The name of the clipbin to update.
     * @param newTitle The new title of the clipbin.
     * @returns A promise that resolves when the update is done.
     */
    updateTitle(clipbinName: string, newTitle: string): Promise<void> {
        const constraints: QueryFieldFilterConstraint[] = [
            where('id', '==', clipbinName),
            where('domain', '==', this.origin),
        ];

        const partialValue: Partial<ClipBinsInfo> = {
            title: newTitle,
            updatedAt:  Timestamp.now(),
            updatedBy: this.authService.getUserEmail()
        };

        return this.updatePartialValueInDocuments(CLIPBINS_INFO_COLLECTION, constraints, partialValue);
    }

    /**
     * Change the status of the clipbin document to inactive.
     *
     * @param clipbinName The name of the clipbin.
     * @returns A promise that resolves when the operation is done.
     */
    inactiveStatusIASClipBins(clipbinName: string): Promise<void> {
        const constraints: QueryFieldFilterConstraint[] = [
            where('id', '==', clipbinName),
            where('domain', '==', this.origin),
        ];

        const partialValue: Partial<ClipBinsInfo> = {
            isActive: false,
            deletedAt: Timestamp.now(),
            deletedBy: this.authService.getUserEmail()
        };

        return this.updatePartialValueInDocuments(CLIPBINS_INFO_COLLECTION, constraints, partialValue);
    }

    /**
     * Retrieve the owner of a clip bin.
     *
     * @param clipbinId The name of the clip bin.
     * @returns An observable that emits the owner's email ID.
     */
    retrieveClipBinOwner(clipbinId: string): Observable<string> {
      const constraints: QueryFieldFilterConstraint[] = [
        where('id', '==', clipbinId),
        where('isActive', '==', true),
      ];
      return this.queryIASClipBins(constraints).pipe(map((data) => (data[0] ? data[0].ownerId || '' : '')));
    }

    private queryIASClipBins(constraints: QueryFieldFilterConstraint[]) {
        return this.queryDocument(CLIPBINS_INFO_COLLECTION, constraints)
            .pipe(
                map(documents =>
                    documents.map(document => {
                        return { ...document.data(), documentId: document.id } as ClipBinsInfo;
                    }))
            );
    }

    /**
     * Retrieves a list of documents from the given collection, filtered by the given constraints.
     *
     * @param collection The Firestore collection to query.
     * @param constraints The QueryFieldFilterConstraint array of conditions to filter the documents.
     * @returns An observable that emits an array of documents that match the given conditions.
     */
    private queryDocument(collection: string, constraints: QueryFieldFilterConstraint[]) {
        return this.firebaseResolver.queryCollectionWithoutLimitSize(collection, constraints);
    }

    /**
     * Updates a partial value of a document in Firestore, using the given collection and constraints.
     *
     * @param collection The Firestore collection to update.
     * @param constraints The QueryFieldFilterConstraint array of conditions to filter the documents.
     * @param partialValue The partial data to update in the documents.
     * @returns A Promise that resolves when the update is done.
     */
    async updatePartialValueInDocuments(collection: string, constraints: QueryFieldFilterConstraint[], partialValue: object) {
        await this.firebaseResolver.updatePartialValueBatchMode(collection, constraints, partialValue);
    }

    private getDB() {
        return this.firebaseResolver.getFirestore() as Firestore;
    }
}
