import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, take } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StorageLocalService<T> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    store$: BehaviorSubject<any> = new BehaviorSubject(undefined);

    // key of the storage
    key: string = '';

    /**
     * Creates a local storage item.
     * If already have a value, it will be overwritten.
     */
    public set<T>(value: T): Observable<T> {
        localStorage.setItem(this.key, JSON.stringify(value));
        this.store$.next(value);
        return this.store$.pipe(take(1));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public update(value: Partial<T>): Observable<any> {
        localStorage.setItem(this.key, JSON.stringify(value));
        this.store$.next(value);
        return this.store$.pipe(take(1));
    }

    public get<T>(): Observable<T> {
        return this.store$.pipe(take(1));
    }

    public clear(): void {
        this.store$.next(undefined);
    }

    public delete(): void {
      this.store$.next(undefined);
      localStorage.removeItem(this.key);
    }
}
